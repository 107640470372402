import React, { useState } from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { marketplace } from '@sellpy/commons'
import { ICONS, Icon, P } from '@sellpy/design-system-react-web'
import { A } from '../uiComponents/index.js'
import { useInferredLocation } from '../../common/hooks/useInferredLocation'
import { mediaQueries } from '../uiComponents/mediaQueries.js'
import constants from './constants.json'

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  position: sticky;
  z-index: 1056;
  background: white;
  bottom: 0;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  ${mediaQueries.tablet} {
    padding-right: 7rem; // leave space for intercom
  }
`
const Container = styled.div`
  padding: 1rem;
  ${mediaQueries.phone} {
    padding: 0;
  }
`

const StyledLink = styled(A)`
  display: block;
`

const REDIRECT_DECLINED = 'REDIRECT_DECLINED'

const RedirectSuggestion = () => {
  const inferredLocation = useInferredLocation()
  const [redirectDeclined, setRedirectDeclined] = useState(
    window.sessionStorage.getItem(REDIRECT_DECLINED)
  )

  const onRedirectDeclined = () => {
    window.sessionStorage.setItem(REDIRECT_DECLINED, true)
    setRedirectDeclined(true)
  }

  const offerRedirect =
    !redirectDeclined &&
    marketplace.BASE_URLS[inferredLocation] &&
    inferredLocation !== process.env.REGION

  const { country, localized, redirectSuggestion, redirectActivated } = constants

  return redirectActivated[inferredLocation] && offerRedirect ? (
    <Footer>
      <Container>
        <P>
          <Trans
            values={{
              country: country[inferredLocation],
              localized: localized[inferredLocation]
            }}
          >
            {redirectSuggestion.text[marketplace.LOCALE[inferredLocation]]}
          </Trans>
          <StyledLink href={marketplace.BASE_URLS[inferredLocation]}>
            {redirectSuggestion.button[marketplace.LOCALE[inferredLocation]]}
          </StyledLink>
        </P>
      </Container>
      <Container onClick={() => onRedirectDeclined()}>
        <Icon name={ICONS.CLOSE} style={{ fontSize: '2rem', cursor: 'pointer' }} />
      </Container>
    </Footer>
  ) : null
}

export default RedirectSuggestion
