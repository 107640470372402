import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Breadcrumbs, Button, H4, Icon } from '@sellpy/design-system-react-web'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import { captureException } from '@sentry/react'
import { P, SIZES } from '../uiComponents'
import LayoutCenteredColumn, {
  CENTERED_COLUMN_VARIANTS
} from '../components/LayoutCenteredColumn.jsx'
import { mediaQueries } from '../uiComponents/mediaQueries'
import FormWrapper from '../components/ui/form/FormWrapper.jsx'
import { showToast } from '../../common/ui/actions.js'
import { TOAST_TYPE } from '../uiComponents/notifications/Toasts.jsx'
import CharityOptions from '../payOuts/charity/CharityOptions.jsx'
import { region } from '../../common/region/region.js'

const ViewBackground = styled.div`
  background-color: ${({ theme }) => theme.color.grey.shade10};
`

const ViewContainer = styled.div`
  padding: 16px 0;
  ${mediaQueries.phone} {
    width: 100%;
  }
  max-width: 664px;
  background-color: ${({ theme }) => theme.color.white.default};
  margin: 16px auto;
`

const BreadCrumbContainer = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 16px;
`

const ContentContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  ${mediaQueries.tablet} {
    flex-direction: column;
  }
`

const setAutomaticPayoutMethodMutation = gql`
  mutation setAutomaticPayoutMethod($method: String!, $charity: String!, $region: String!) {
    setAutomaticPayoutMethod(method: $method, region: $region, donationOrganization: $charity) {
      donationOrganization
    }
  }
`

const CharityAccountSetup = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['payoutSetup', 'common'])

  const [setAutomaticPayoutMethod] = useMutation(setAutomaticPayoutMethodMutation, {
    onError: () => {
      dispatch(
        showToast({
          header: t('common:errorToast.header'),
          body: t('common:errorToast.body'),
          type: TOAST_TYPE.ERROR
        })
      )
    }
  })

  const methods = useForm()
  const {
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = methods

  const onSubmit = useCallback(
    async (data) => {
      try {
        const response = await setAutomaticPayoutMethod({
          variables: {
            region: region(),
            method: 'charity',
            charity: data.donationOrganization
          }
        })
        const automaticPayout = response?.data?.setAutomaticPayoutMethod
        if (automaticPayout?.donationOrganization) {
          navigate('/payout-setup/confirmation?method=charity')
        } else {
          dispatch(
            showToast({
              header: t('common:errorToast.header'),
              body: t('common:errorToast.body'),
              type: TOAST_TYPE.ERROR
            })
          )
        }
      } catch (e) {
        captureException(e)
        dispatch(
          showToast({
            header: t('common:errorToast.header'),
            body: t('common:errorToast.body'),
            type: TOAST_TYPE.ERROR
          })
        )
      }
    },
    [dispatch, navigate, t]
  )

  return (
    <>
      <ViewBackground>
        <LayoutCenteredColumn variant={CENTERED_COLUMN_VARIANTS.NARROW}>
          <BreadCrumbContainer>
            <Breadcrumbs
              options={[
                {
                  link: '/payout-setup',
                  label: (
                    <P design='body2' noMargin={true}>
                      <Icon
                        name={'ARROW_LEFT'}
                        style={{
                          marginRight: '8px'
                        }}
                      />
                      {t('mainPage.description.header')}
                    </P>
                  )
                }
              ]}
            />
          </BreadCrumbContainer>
          <ViewContainer>
            <ContentContainer>
              <H4>{t('choice.charity.header')}</H4>
              <P design='body3' noMargin>
                {t('choice.charity.body')}
              </P>
              <FormProvider {...methods}>
                <FormWrapper style={{ marginTop: '20px' }} onSubmit={handleSubmit(onSubmit)}>
                  <CharityOptions />
                  <ButtonContainer>
                    <Button
                      variant={'filled'}
                      size={SIZES.SMALL}
                      fullWidth={false}
                      label={t('completeButton.label')}
                      disabled={!isValid || isSubmitting}
                      loading={isSubmitting}
                    />
                  </ButtonContainer>
                </FormWrapper>
              </FormProvider>
            </ContentContainer>
          </ViewContainer>
        </LayoutCenteredColumn>
      </ViewBackground>
    </>
  )
}

export default CharityAccountSetup
