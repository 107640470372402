import React from 'react'
import {
  DjurensRatt,
  FourPaws,
  RedCross,
  SaveTheChildren,
  SosChildrensVillages,
  StockholmsStadsmission,
  UNHCR,
  WWF
} from '@sellpy/design-system-react-web'

const height = 42

const donationsSvgs = {
  unhcr: <UNHCR height={height} />,
  stockholmsStadsmission: <StockholmsStadsmission height={height} />,
  saveTheChildren: <SaveTheChildren height={height} />,
  sosBarnbyar: <SosChildrensVillages height={height} />,
  djurensRatt: <DjurensRatt height={height} />,
  fourPaws: <FourPaws height={height} />,
  raddaBarner: <SaveTheChildren height={height} />,
  redCross: <RedCross height={height} />,
  wwfRaddaOstersjon: <WWF height={height} />
}

const DonationSvg = ({ name }) => donationsSvgs[name]

export default DonationSvg
