import React from 'react'
import PropTypes from 'prop-types'
import { dynamicDescription, readableUrls } from '@sellpy/commons'
import { Link } from 'react-router-dom'
import { locale, localizedMetadata } from '../../common/region/config'

const BaseComponent = ({
  component: Component,
  item,
  outputFunction,
  itemTitleFunction,
  defaultValue,
  ...rest
}) => {
  const metadata = item.get(localizedMetadata) || item.get('metadata')
  const data = {
    metadata,
    titleOrder: item.get('titleOutputOrder'),
    bodyOrder: item.get('bodyOutputOrder'),
    locale
  }

  return <Component {...rest}>{(metadata && outputFunction(data)) || defaultValue}</Component>
}

BaseComponent.propTypes = {
  component: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
}

export const ItemTitle = (props) => (
  <BaseComponent outputFunction={dynamicDescription.itemTitle} {...props} />
)

export const ItemSubtitle = ({ defaultValue, ...rest }) => (
  <BaseComponent
    outputFunction={dynamicDescription.itemSubtitle}
    itemTitleFunction={dynamicDescription.itemTitle}
    defaultValue={rest.item.get('metadata') ? defaultValue : rest.item.get('headline')}
    {...rest}
  />
)

export const ItemFullTitle = (props) => (
  <BaseComponent outputFunction={dynamicDescription.itemFullTitle} {...props} />
)

export const ItemBody = (props) => (
  <BaseComponent outputFunction={dynamicDescription.itemBody} {...props} />
)

export const ItemLink = ({ item, children, className, ...rest }) => {
  const itemLink = readableUrls.itemLinkWithSlug({
    /* ItemLink is re-used on both index and /sell. Index uses algolia, and /sell uses elastic where the "objectId" casing differs */
    itemId: item.get('objectID') || item.get('objectId'),
    metadata: item.get('metadata'),
    bodyOutputOrder: item.get('bodyOutputOrder'),
    locale
  })
  return (
    <Link reloadDocument={window.appUpdateAvailable} to={itemLink} className={className} {...rest}>
      {children}
    </Link>
  )
}
