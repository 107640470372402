import { Map, List, Set, fromJS } from 'immutable'
import { currency as currencyUtil, ITEM_STATUS, itemState, money } from '@sellpy/commons'
import { staticImageAssets } from 'config'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime, Duration } from 'luxon'
import { localizedMetadata } from '../../common/region/config'
import { getAlgoliaItems, getItemValue } from '../../common/items/actions'
import { zeroMoney } from '../payOuts/utils'
import { analyticsViewContent } from '../../common/analytics/actions'
import { itemStateMap } from './itemStatus'

export const useAlgoliaPrice = ({ itemId }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAlgoliaItems(Set([itemId])))
  }, [itemId, dispatch])

  const algoliaItem = useSelector((state) => state.items.algoliaItems.get(itemId))
  const priceObject =
    algoliaItem && money.toBaseUnit(algoliaItem.get(`price_${process.env.REGION}`).toJS())

  return priceObject || zeroMoney()
}

export const useSellerItemValue = (itemId) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getItemValue(itemId))
  }, [dispatch, itemId])
  const itemValues = useSelector((state) => state.items.itemValues)
  return itemValues.get(itemId)
}

export const isOwnerOfItem = (item, userId) => item && userId && item.get('user') === userId

export const isItemForSale = (item) => itemState.ongoingSale(item)

export const EMPTY_METADATA_ALLOWED_SINCE = new Date('2020-01-01')

export const isViewable = ({ item, isOwner }) => {
  if (!item) return false
  const metadata = item.get(localizedMetadata)
  const _forSeller =
    metadata && metadata.get('type')
      ? true
      : new Date(item.get('createdAt')) < EMPTY_METADATA_ALLOWED_SINCE

  const _forBuyer =
    metadata &&
    metadata.get('type') &&
    itemState.sellable(item) &&
    !itemState.pendingSale(item) &&
    !itemState.removed(item)

  return isOwner ? _forSeller : _forBuyer
}

export const defaultPhoto = (itemStatus) => {
  return fromJS({ url: defaultImage(itemStatus) })
}

export const defaultImage = (itemStatus) =>
  ({
    donated: `${staticImageAssets}sellerInterface/saleItems/april2021/donate-to-charity.png`,
    pendingReevaluation: `${staticImageAssets}sellerInterface/saleItems/april2021/sale-request.png`,
    sellerReturned: `${staticImageAssets}sellerInterface/saleItems/april2021/sent-back-to-seller.jpg`,
    rejected: `${staticImageAssets}sellerInterface/saleItems/april2021/rejected-items.jpg`
  }[itemStateMap[itemStatus]] ||
  `${staticImageAssets}sellerInterface/saleItems/april2021/in-progress.png`)

export const calculateCustomerShare = (value, pricingModel, sale) => {
  const saleConfig = sale && sale.get('saleConfig')
  if (saleConfig) {
    const share = saleConfig.get('customerValueShare')
    return money.multiply(share)(value)
  }

  const currency = Map.isMap(value) ? value.get('currency') : value.currency
  const customerShareThreshold = currencyUtil.customerShareThreshold(currency)
  if (money.lte(value, customerShareThreshold)) return money.multiply(0.4)(value)

  // Old customerValueShare for expensive item used to be 0.9, now it is 0.7
  const share = pricingModel <= 4 ? 0.9 : 0.7
  const shareAboveThreshold = money.multiply(share)(money.subtract(value)(customerShareThreshold))
  const shareBelowThreshold = money.multiply(0.4)(customerShareThreshold)
  return money.add(shareAboveThreshold, shareBelowThreshold)
}

export const customerShareIncludingFee = ({ value, pricingModel, sale, adFee }) =>
  money.subtract(calculateCustomerShare(value, pricingModel, sale))(adFee)

export const calculateP2pShare = (share) => ({ value }) => money.multiply(share)(value)

export const useTrackViewItem = ({ item, price }) => {
  const dispatch = useDispatch()
  const itemId = item?.get('objectId')
  const amount = price.amount

  useEffect(() => {
    if (!itemId || !amount) return
    analyticsViewContent({ item, itemId, priceObject: price })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, amount, dispatch])
}

const FLIXSTOCK_DEWRINKLE_IMAGE_PATH = 'flixstock-images/dewrinkle/'

export const selectPhotosForUse = (item) => {
  return selectPhotosByItemStatus(item).filter(
    (photo) => !photo.get('url')?.includes(FLIXSTOCK_DEWRINKLE_IMAGE_PATH)
  )
}

export const selectPhotosForUseSeller = (item) => {
  return selectPhotosByItemStatusSeller(item).filter(
    (photo) => !photo.get('url')?.includes(FLIXSTOCK_DEWRINKLE_IMAGE_PATH)
  )
}

export const selectImagesForUse = (images) => {
  return images.filter((url) => !url?.includes(FLIXSTOCK_DEWRINKLE_IMAGE_PATH))
}

export const selectPhotosByItemStatus = (item) => {
  return item.get('photos') || List([defaultPhoto(item.get('itemStatus'))])
}

export const selectPhotosByItemStatusSeller = (item) => {
  if (item.get('itemStatus') === ITEM_STATUS.REGISTRERAD)
    return List([defaultPhoto(ITEM_STATUS.REGISTRERAD)])
  return item.get('photos') || List([defaultPhoto(item.get('itemStatus'))])
}

export const selectImagesByItemStatus = (item) => {
  if (item.get('itemStatus') === ITEM_STATUS.REGISTRERAD)
    return List([defaultImage(ITEM_STATUS.REGISTRERAD)])
  return item.get('images') || List([defaultImage(item.get('itemStatus'))])
}

const WEEKEND_CAMPAIGN_CURATION = 'c3b4b6fd-57ee-4b92-8c02-b67eb9ce3272'

export const isWithinWeekendCampaignTimeRange = () =>
  DateTime.utc() > DateTime.fromISO('2024-05-31T00:00:00Z') &&
  DateTime.utc() < DateTime.fromISO('2024-06-03T00:00:00Z')

export const isWeekendCampaignItem = (item) =>
  item.get('featuredIn')?.includes(WEEKEND_CAMPAIGN_CURATION) && isWithinWeekendCampaignTimeRange()

const _getDurations = (freightAlt) => {
  const [min, max] = freightAlt.estimatedShipping.interval.split('/')
  const earliest = Duration.fromISO(min)
  const latest = Duration.fromISO(max)
  return { earliest, latest }
}

const _compare = (a, b) => {
  const aDurations = _getDurations(a)
  const bDurations = _getDurations(b)
  return aDurations.earliest - bDurations.earliest || aDurations.latest - bDurations.latest
}

const stableSort = (arr) =>
  arr
    .map((item, index) => ({ index, item }))
    .sort((a, b) => _compare(a.item, b.item) || a.index - b.index)
    .map(({ item }) => item)

export const getFastestFreightAlternative = (freightAlternatives) => {
  const sorted = stableSort(freightAlternatives)
  return sorted[0]
}

export const getIntervalDatesForFreightAlternative = (freightAlternative) => {
  const [min, max] = freightAlternative.estimatedShipping.interval.split('/')
  return {
    earliest: DateTime.local().plus(Duration.fromISO(min)),
    latest: DateTime.local().plus(Duration.fromISO(max))
  }
}
