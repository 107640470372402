import Parse from 'parse'

const abTests = {
  reservedAdFeesBalance: {
    min: 0.3,
    max: 0.375,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['user']
  },
  reservedAdFeesBalanceNew: {
    min: 0.85,
    max: 0.925,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['user']
  },
  greenBagOptIn: {
    min: 0.5,
    max: 1,
    regions: ['DK', 'FI'],
    fractions: ['client']
  },
  detailsPDPv1: {
    min: 0,
    max: 0.2,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['user']
  },
  detailsPDPv2: {
    min: 0.21,
    max: 0.4,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['user']
  },
  quickFilters: {
    min: 0,
    max: 0.5,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['client']
  },
  deliveryPromiseInCart: {
    min: 0.1,
    max: 0.2,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['client']
  },
  returnInfoBox: {
    min: 0.51,
    max: 0.6,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['user']
  }
}

export const setAbTestFraction = () => {
  getABTestFraction()
}

export const getABTestFraction = () => {
  if (!window.localStorage.getItem('sellpyAbTest')) {
    window.localStorage.setItem('sellpyAbTest', Math.random())
  }
  return window.localStorage.getItem('sellpyAbTest')
}

export const inAbTestTestGroup = (testName) => _inTestGroup(testName)

export const activeAbTests = () => Object.keys(abTests).filter(_inTestGroup)

const _inTestGroup = (testName) => {
  const abTest = abTests[testName]
  if (!abTest) return false
  const fractions = {
    user: Parse.User.current() && Parse.User.current().get('userAbTestFraction'),
    client: getABTestFraction()
  }
  const fraction = abTest.fractions
    .map((fractionId) => fractions[fractionId])
    .filter((fraction) => fraction != null)[0]
  return (
    abTest.min <= fraction &&
    abTest.max > fraction &&
    (abTest.regions || []).includes(process.env.REGION)
  )
}
