import Parse from 'parse'
import { fromJS } from 'immutable'
import { user as userTools, auth } from '@sellpy/commons'
import queryString from 'qs'
import omit from 'lodash/omit'
import config from 'config'
import {
  setUser as setSentryUser,
  setContext as setSentryContext,
  captureException
} from '@sentry/react'
import { showToast } from '../ui/actions'
import { getCartContent } from '../cart/actions'
import {
  analyticsAddTrackedSearch,
  analyticsLogin,
  analyticsSignUp,
  analyticsSetUserId,
  analyticsUnsetUserId,
  analyticsEnableAnalytics,
  analyticsDisableAnalytics,
  analyticsEnableExternalMarketing,
  analyticsDisableExternalMarketing,
  analyticsSetUserEmail,
  analyticsUnsetUserEmail,
  analyticsSetUserPhone,
  analyticsUnsetUserPhone
} from '../analytics/actions'
import { cacheEntities } from '../entities/actions'
import { isFeatureEnabled, FEATURE_SELL } from '../../client/featureToggle'
import { activeAbTests, getABTestFraction } from '../../common/analytics/abTest'
import { internationalBankAccountFields } from '../region/config'
import { runCloudCached, runQueryCached } from '../lib/parseTools'
import { parseError } from '../error'
import { tagManager } from '../../client/lib/googleTagManager'
import { router } from '../../client/routing/Routes.jsx'
import { getApolloClient } from '../../client/apollo/apolloClientSingleton'
import {
  PERMISSION_TYPE_ANALYTICS,
  PERMISSION_TYPE_EMAIL_MARKETING,
  PERMISSION_TYPE_EXTERNAL_MARKETING
} from './dataHandling'
import * as facebook from './facebook'

export const USER_BELONGS_TO_ROLES_LOADING = 'USER_BELONGS_TO_ROLES_LOADING'
export const USER_BELONGS_TO_ROLES_SUCCESS = 'USER_BELONGS_TO_ROLES_SUCCESS'
export const USER_BELONGS_TO_ROLES_RESET = 'USER_BELONGS_TO_ROLES_RESET'
export const userBelongsToRoles = () => async (dispatch) => {
  dispatch({ type: USER_BELONGS_TO_ROLES_LOADING })
  const rolesValidated = await Parse.Cloud.run('getUserRoleBelonging')
  dispatch({ type: USER_BELONGS_TO_ROLES_SUCCESS, roles: rolesValidated })
}
export const resetUserRoles = () => (dispatch) => dispatch({ type: USER_BELONGS_TO_ROLES_RESET })

export const USER_DISCARD_FACEBOOK_ERROR = 'USER_DISCARD_FACEBOOK_ERROR'
export const discardFacebookError = () => {
  return {
    type: USER_DISCARD_FACEBOOK_ERROR
  }
}

export const resetAuthAndRefresh = () => {
  Parse.User.logOut().catch(() => window.location.reload(true))
}

const handleInvalidSessionToken = (error) => {
  if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
    resetAuthAndRefresh()
  } else {
    throw error
  }
}

export const USER_ALREADY_LOGGED_IN_LOADING = 'USER_ALREADY_LOGGED_IN_LOADING'
export const USER_ALREADY_LOGGED_IN_SUCCESS = 'USER_ALREADY_LOGGED_IN_SUCCESS'

export const setP2pOptOutForUser = ({ p2pOptOut }) => (dispatch) => {
  Parse.Cloud.run('setP2pOptOutForUser', { p2pOptOut }).then((parseUser) => {
    dispatch({ type: SET_USER_PROFILE_SUCCESS, payload: parseUser })
  })
}

export function tryLoggedIn() {
  return (dispatch) => {
    dispatch(isFirstTimeMarketCustomer())
    const parseUser = Parse.User.current()
    if (parseUser) {
      Parse.Cloud.run('getCustomerImpersonation').then((impersonation) =>
        dispatch(cacheEntities(impersonation))
      )
      dispatch({
        type: USER_ALREADY_LOGGED_IN_LOADING,
        payload: parseUser
      })
      return parseUser
        .fetch()
        .then(() => {
          dispatch(alreadyLoggedInHook(parseUser))
          dispatch({ type: USER_ALREADY_LOGGED_IN_SUCCESS, payload: parseUser })
        })
        .catch(handleInvalidSessionToken)
    } else {
      dispatch(getDataUsageConsentForUser({}, { cacheBust: true }))
    }
  }
}

export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS'

export const fetchCurrentUser = () => {
  return (dispatch) => {
    const parseUser = Parse.User.current()
    if (parseUser) {
      return parseUser
        .fetch()
        .then((fetchedUser) => dispatch({ type: USER_FETCH_SUCCESS, payload: fetchedUser }))
    }
  }
}

export const USER_FACEBOOK_STATUS_LOADING = 'USER_FACEBOOK_STATUS_LOADING'
export const USER_FACEBOOK_ALREADY_LOGGED_IN = 'USER_FACEBOOK_ALREADY_LOGGED_IN'

export const facebookSignup = ({ dataUsageConsent }) => {
  return async (dispatch) => {
    dispatch({ type: USER_FACEBOOK_LOGIN_LOADING })
    try {
      const { authResponse } = await aquireEmailRights()
      await signupWithFacebook({
        dispatch,
        authData: authResponse,
        dataUsageConsent
      })
    } catch (error) {
      dispatch({ type: USER_FACEBOOK_LOGIN_ERROR, payload: parseError(error).message })
      throw error
    }
  }
}

const signupWithFacebook = ({ dispatch, authData, dataUsageConsent }) => {
  return Parse.Cloud.run('facebookSignup', {
    id: authData.userID,
    access_token: authData.accessToken,
    dataUsageConsent,
    region: process.env.REGION,
    abTestFraction: getABTestFraction()
  }).then((sessionToken) => {
    return Parse.User.become(sessionToken).then((user) => {
      dispatch({
        type: USER_FACEBOOK_LOGIN_SUCCESS,
        payload: user
      })
      analyticsSignUp('Facebook')

      dispatch(afterLoggedInHook(user, { signup: true }))
      return user
    })
  })
}

export const USER_FACEBOOK_LOGIN_LOADING = 'USER_FACEBOOK_LOGIN_LOADING'
export const USER_FACEBOOK_LOGIN_SUCCESS = 'USER_FACEBOOK_LOGIN_SUCCESS'
export const USER_FACEBOOK_LOGIN_ERROR = 'USER_FACEBOOK_LOGIN_ERROR'

export const facebookLogin = () => {
  return (dispatch) => {
    dispatch({ type: USER_FACEBOOK_LOGIN_LOADING })
    return aquireEmailRights().then(
      ({ status, authResponse }) => {
        return loginWithFacebook({
          dispatch,
          authData: authResponse
        }).catch((serverError) => {
          dispatch({ type: USER_FACEBOOK_LOGIN_ERROR, payload: parseError(serverError).message })
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ serverError, authResponse })
        })
      },
      (error) => {
        dispatch({ type: USER_FACEBOOK_LOGIN_ERROR, payload: parseError(error).message })
        return Promise.reject(error)
      }
    )
  }
}

const aquireEmailRights = () => {
  return facebook.login().then(({ status, authResponse }) => {
    if (authResponse && authResponse.grantedScopes.split(',').includes('email')) {
      return { status, authResponse }
    } else {
      return facebook.relogin().then(({ status, authResponse }) => {
        if (authResponse && authResponse.grantedScopes.split(',').includes('email')) {
          return { status, authResponse }
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ type: 'noEmailAcquired' })
        }
      })
    }
  })
}

const loginWithFacebook = ({ dispatch, authData }) => {
  return Parse.Cloud.run('facebookLogin', {
    id: authData.userID,
    access_token: authData.accessToken
  }).then((sessionToken) => {
    return Parse.User.become(sessionToken).then((user) => {
      dispatch({
        type: USER_FACEBOOK_LOGIN_SUCCESS,
        payload: user
      })
      analyticsLogin('Facebook', user.id, user)
      dispatch(afterLoggedInHook(user))
      return user
    })
  })
}

export const USER_LOGGED_IN_WITH_SESSION_TOKEN = 'USER_LOGGED_IN_WITH_SESSION_TOKEN'

export const loginWithSessionToken = (sessionToken) => {
  return (dispatch) => {
    return Parse.User.become(sessionToken).then((user) => {
      dispatch({
        type: USER_LOGGED_IN_WITH_SESSION_TOKEN,
        payload: user
      })
      return user
    })
  }
}

export const USER_PARSE_LOGIN_SUCCESS = 'USER_PARSE_LOGIN_SUCCESS'

// From https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
const hashFunction = async (text) => {
  const msgUint8 = new TextEncoder().encode(text)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8)
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}

const PARSE_INVALID_LOGIN_PARAMS_ERROR_CODE = 101
const PARSE_GENERAL_INVALID_LOGIN_ERROR_CODE = 141
const PARSE_USERNAME_TAKEN_ERROR_CODE = 202

const _retrieveLoginAuth = async ({ email, password }) => {
  const loginToken = await Parse.Cloud.run('getLoginToken')
  const { iteration, hashDurationMs } = await auth.generateValidLoginHash(hashFunction)({
    loginToken,
    username: email,
    password
  })

  return { loginToken, iteration, hashDurationMs }
}

const _loginParse = async ({ email, password, dispatch }) => {
  email = email.toLowerCase()
  const { loginToken, iteration, hashDurationMs } = await _retrieveLoginAuth({ email, password })
  const sessionToken = email.match(/@sellpy\.se$/)
    ? await Parse.Cloud.run('logInAdmin', {
        username: email,
        password,
        loginToken,
        iteration,
        hashDurationMs
      })
    : await Parse.Cloud.run('loginEmail', {
        email,
        password,
        loginToken,
        iteration,
        hashDurationMs
      })

  const user = await Parse.User.become(sessionToken)
  dispatch({ type: USER_PARSE_LOGIN_SUCCESS, payload: user })
  analyticsLogin('Parse', user.id, user)
  const apolloClient = getApolloClient()
  await apolloClient.clearStore()
  return user
}

export const newLoginParse = ({ email, password }) => {
  return async (dispatch) => {
    try {
      const user = await _loginParse({ email, password, dispatch })
      dispatch(afterLoggedInHook(user, { redirect: false }))
    } catch (error) {
      if (
        [PARSE_GENERAL_INVALID_LOGIN_ERROR_CODE, PARSE_INVALID_LOGIN_PARAMS_ERROR_CODE].includes(
          error.code
        )
      ) {
        const userExists = await Parse.Cloud.run('userExists', { email })
        if (userExists) {
          throw new Error('wrongPassword')
        } else {
          throw new Error('noSuchAccount')
        }
      } else {
        throw error
      }
    }
  }
}

export const USER_PARSE_SIGNUP_SUCCESS = 'USER_PARSE_SIGNUP_SUCCESS'

export const signupParse = ({ email, password, dataUsageConsent }) => {
  return async (dispatch) => {
    try {
      const sessionToken = await Parse.Cloud.run('signupEmail', {
        email,
        password,
        dataUsageConsent,
        region: process.env.REGION,
        abTestFraction: getABTestFraction()
      })

      const user = await Parse.User.become(sessionToken)
      dispatch({ type: USER_PARSE_SIGNUP_SUCCESS, payload: user })
      analyticsSignUp('Parse')
      dispatch(afterLoggedInHook(user, { signup: true, redirect: false }))
    } catch (error) {
      if (error.code === PARSE_USERNAME_TAKEN_ERROR_CODE) {
        throw new Error('userAlreadyExists')
      } else {
        throw error
      }
    }
  }
}

const getQueryFromLocation = (location) =>
  queryString.parse(location.search, { ignoreQueryPrefix: true })

export const redirectAfterLogin = () => {
  return (dispatch) => {
    const pathname = router.state.location.pathname
    const state = router.state.location.state
    const { authToken, ...query } = getQueryFromLocation(router.state.location)
    if (state) {
      if (state.nextAction) {
        const { name, params } = state.nextAction
        if (name === 'addTrackedSearch') {
          dispatch(trackedSearchAdd(params.data, params.toastMessage))
        }
        const trackedSearchURL = new URL(params.data.url)
        router.navigate(trackedSearchURL.pathname + trackedSearchURL.search || '/home')
      } else if (state.nextLocation) {
        router.navigate(state.nextLocation, { replace: true })
      }
    } else if (authToken) {
      router.navigate({ pathname, search: queryString.stringify(query) }, { replace: true })
      // Since we prevent tracking initialization in main.js when we detect an authToken query parameter in the url we need to initialize it now that the authtoken is consumed.
      if (window.gtmDataLayer == null) {
        tagManager.initialize({
          gtmId: config.googleTagManagerId,
          dataLayerName: 'gtmDataLayer'
        })
      }
    } else {
      router.navigate(
        { pathname: isFeatureEnabled(FEATURE_SELL) ? '/home' : '/' },
        { replace: true }
      )
    }
  }
}

const alreadyLoggedInHook = (user, { resetWishlist } = { resetWishlist: false }) => {
  return (dispatch) => {
    setSentryUser({ id: user?.id })
    setSentryContext('A/B Test', {
      clientFraction: getABTestFraction(),
      activeTests: activeAbTests()
    })
    if (user.get('email') && user.get('email').includes('@sellpy.se')) {
      dispatch(userBelongsToRoles())
    }
    dispatch(getCartContent())
    dispatch(getDataUsageConsentForUser())
  }
}

export const afterLoggedInHook = (user, options) => {
  options = { redirect: true, signup: false, resetWishlist: true, ...options }
  return (dispatch) => {
    dispatch(alreadyLoggedInHook(user, options))
    if (options.redirect) dispatch(redirectAfterLogin())
  }
}

export const USER_LOGOUT = 'USER_LOGOUT'
export function logout(nextLocation) {
  return (dispatch) => {
    return Parse.User.logOut().then(async () => {
      // https://support.google.com/tagmanager/answer/4565987?hl=en
      // The Google Analytics User ID policy requires that you stop any measurement
      // based on the user ID once the user signs out of your website.
      analyticsUnsetUserId()
      setSentryUser(null)
      const apolloClient = getApolloClient()
      await apolloClient.clearStore()
      dispatch({ type: USER_LOGOUT })
      // USER_LOGOUT resets the state, so it must be populated with header categories,
      // dataUsageConsent and freight alternatives
      dispatch(getDataUsageConsentForUser({}, { cacheBust: true }))
      try {
        window.Intercom('shutdown')
      } catch (e) {}
      nextLocation && router.navigate(nextLocation)
    })
  }
}

export const getDataUsageConsentWithEclub = ({ eclub }) => (dispatch) =>
  Parse.Cloud.run('getDataUsageConsentWithEclub', { eclub }).then(({ emailMarketing }) => {
    dispatch({ type: DATA_USAGE_CONSENT_RETRIEVED, consentMap: fromJS({ emailMarketing }) })
  })

export const DATA_USAGE_CONSENT_RETRIEVED = 'DATA_USAGE_CONSENT_RETREIVED'

export const getDataUsageConsentForUser = (params = {}, cacheOptions) => {
  return (dispatch) => {
    return runCloudCached((consentMap) => {
      consentMap = fromJS(consentMap)
      if (consentMap.get(PERMISSION_TYPE_EXTERNAL_MARKETING)) {
        analyticsEnableExternalMarketing()
      } else {
        analyticsDisableExternalMarketing()
      }
      if (consentMap.get(PERMISSION_TYPE_ANALYTICS)) {
        analyticsEnableAnalytics()
        if (userTools.userIsFull(Parse.User.current())) {
          analyticsSetUserId()
        } else {
          analyticsUnsetUserId()
        }
      } else {
        analyticsDisableAnalytics()
        analyticsUnsetUserId()
      }
      if (
        consentMap.get(PERMISSION_TYPE_EXTERNAL_MARKETING) &&
        consentMap.get(PERMISSION_TYPE_ANALYTICS)
      ) {
        const parseUser = Parse.User.current()
        parseUser && analyticsSetUserEmail({ email: parseUser.get('email') })
        parseUser && analyticsSetUserPhone({ phone: parseUser.get('address')?.phone })
      } else {
        analyticsUnsetUserEmail()
        analyticsUnsetUserPhone()
      }
      dispatch({ type: DATA_USAGE_CONSENT_RETRIEVED, consentMap })
      return consentMap
    }, cacheOptions)('getDataUsageConsentForUser', params).catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
  }
}

export const changeEmailMarketingWithEclub = ({ eclub, consent, setSubscribed }) => () =>
  Parse.Cloud.run('changeEmailMarketingWithEclub', { eclub, consent }).then(() =>
    setSubscribed(false)
  )

export const DATA_USAGE_CONSENT_SET = 'DATA_USAGE_CONSENT_SET'

export const setDataUsageConsentForUser = ({ type, consent, email }) => {
  return (dispatch, getState) => {
    dispatch({ type: DATA_USAGE_CONSENT_SET, dataType: type, consent })
    return Parse.Cloud.run('setDataUsageConsentForUser', {
      type,
      consent,
      email,
      region: process.env.REGION
    })
      .then((payload) => {
        const consentResult = payload.get('consent')
        if (type === PERMISSION_TYPE_EXTERNAL_MARKETING) {
          if (consentResult) {
            analyticsEnableExternalMarketing()
          } else {
            analyticsDisableExternalMarketing()
          }
        }
        if (type === PERMISSION_TYPE_ANALYTICS) {
          if (consentResult) {
            analyticsEnableAnalytics()
            if (userTools.userIsFull(Parse.User.current())) {
              analyticsSetUserId()
            } else {
              analyticsUnsetUserId()
            }
          } else {
            analyticsDisableAnalytics()
            analyticsUnsetUserId()
          }
        }
        dispatch({ type: DATA_USAGE_CONSENT_SET, dataType: type, consent: consentResult })
      })
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }
}

export const REGISTER_USER_REMOVE_REQUEST = 'REGISTER_USER_REMOVE_REQUEST'
export const registerUserRemoveRequest = () => {
  return (dispatch) => {
    return Parse.Cloud.run('registerUserRemoveRequest')
      .then(() => {
        dispatch({ type: REGISTER_USER_REMOVE_REQUEST })
        // TODO: use resetAuthAndRefresh instead? Check with Jonas!
        dispatch(logout('/')).catch(() => window.location.replace(window.location.origin))
      })
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }
}

export const registerUserInformationRetrievalRequest = () =>
  Parse.Cloud.run('registerUserInformationRetrievalRequest')

export const registerDac7InformationRetrievalRequest = ({ userSaleReportId }) =>
  Parse.Cloud.run('registerDac7InformationRetrievalRequest', { userSaleReportId })

export const resetPassword = (email) => Parse.Cloud.run('userRequestResetPasswordLink', { email })

export const SET_USER_ADDRESS_LOADING = 'SET_USER_ADDRESS_LOADING'
export const SET_USER_ADDRESS_SUCCESS = 'SET_USER_ADDRESS_SUCCESS'
export const SET_USER_ADDRESS_ERROR = 'SET_USER_ADDRESS_ERROR'

export const setUserAddress = (address) => {
  return (dispatch) => {
    dispatch({ type: SET_USER_ADDRESS_LOADING })
    const user = Parse.User.current()

    return user
      .save({ address })
      .then((savedUser) => {
        dispatch({ type: SET_USER_ADDRESS_SUCCESS, payload: savedUser })
        return savedUser
      })
      .catch((error) => {
        dispatch({ type: SET_USER_ADDRESS_ERROR, error })
        return Promise.reject(error)
      })
  }
}

export const ENABLE_PROFILE_FORM = 'ENABLE_PROFILE_FORM'
export const DISABLE_PROFILE_FORM = 'DISABLE_PROFILE_FORM'

export const enableProfileForm = () => {
  return {
    type: ENABLE_PROFILE_FORM
  }
}

export const disableProfileForm = () => {
  return {
    type: DISABLE_PROFILE_FORM
  }
}

export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'

export const updatePassword = ({ password }) => {
  return (dispatch) => {
    return Parse.Cloud.run('updatePassword', { password }).then((sessionToken) => {
      return Parse.User.become(sessionToken).then((user) => {
        dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: user })
        dispatch(cacheEntities(user))
        dispatch(afterLoggedInHook(user))
      })
    })
  }
}

export const getPhoneDataFromNumber = (number) => () => {
  return Parse.Cloud.run('getPhoneDataFromNumber', { phoneNumber: number })
}

export const SET_USER_PROFILE_LOADING = 'SET_USER_PROFILE_LOADING'
export const SET_USER_PROFILE_SUCCESS = 'SET_USER_PROFILE_SUCCESS'
export const SET_USER_PROFILE_ERROR = 'SET_USER_PROFILE_ERROR'

export const setUserProfile = ({ address }) => {
  return async (dispatch) => {
    dispatch({ type: SET_USER_PROFILE_LOADING })
    try {
      const savedUser = await Parse.Cloud.run('setProfile', { address })
      dispatch({ type: SET_USER_PROFILE_SUCCESS, payload: savedUser })
    } catch (error) {
      dispatch({ type: SET_USER_PROFILE_ERROR, payload: error })
      throw error
    }
  }
}

export const getFavoriteLists = async (dispatch) => {
  if (Parse.User.current()) {
    return runCloudCached((favoritList) => {
      dispatch(cacheEntities(favoritList))
    })('getFavoriteListsForUser')
  }
}

export const getItemsForSaleForUser = async (dispatch) => {
  return runCloudCached((items) => {
    dispatch(cacheEntities(items))
  })('getItemsForSale')
}

export const trackedSearchAdd = ({ name, url }, toastMessage) => {
  return (dispatch) => {
    return Parse.Cloud.run('trackSearch', {
      name,
      url,
      region: process.env.REGION
    })
      .then((trackedSearch) => {
        console.log({ url, trackedSearch })
        dispatch(cacheEntities(trackedSearch))
        dispatch(showToast(toastMessage.success))
        analyticsAddTrackedSearch({ trackedSearch })
      })
      .catch((error) => {
        const type = error.message?.details?.trackedSearch || 'error'
        dispatch(showToast(toastMessage[type]))
      })
  }
}

export const createSearchProfile = ({ name, searchState }) => (dispatch) => {
  return Parse.Cloud.run('createSearchProfile', {
    name,
    searchState
  }).then((searchProfile) => {
    dispatch(cacheEntities(searchProfile))
  })
}

export const editSearchProfile = ({ searchProfileId, name, searchState }) => (dispatch) => {
  return Parse.Cloud.run('editSearchProfile', {
    searchProfileId,
    name,
    searchState
  }).then((searchProfile) => {
    dispatch(cacheEntities(searchProfile))
  })
}

export const getSearchProfiles = () => async (dispatch) => {
  await runQueryCached((searchProfiles) => dispatch(cacheEntities(searchProfiles)))(
    new Parse.Query('SearchProfile')
      .equalTo('user', Parse.User.current())
      .equalTo('status', 'active')
  )
}

export const deleteSearchProfile = (searchProfile) => (dispatch) => {
  return Parse.Cloud.run('deleteSearchProfile', {
    searchProfileId: searchProfile.get('objectId')
  }).then((searchProfile) => {
    dispatch(cacheEntities(searchProfile))
  })
}

export const resetFailedPayouts = ({ clearingNumber, accountNumber }) => {
  return async (dispatch) => {
    if (!internationalBankAccountFields) {
      const claimPayouts = await Parse.Cloud.run('resetFailedClaimPayouts', {
        newClearingNumber: clearingNumber,
        newAccountNumber: accountNumber
      })
      dispatch(cacheEntities(claimPayouts || []))
    }
  }
}

export const signupPasswordless = ({ email, toastMessage }) => {
  return (dispatch, getState) => {
    return Parse.Cloud.run('passwordless_signup', {
      email,
      region: process.env.REGION,
      abTestFraction: getABTestFraction()
    }).then(() => {
      dispatch(showToast(toastMessage))
    })
  }
}

export const USER_PASSWORDLESS_LOGIN_LOADING = 'USER_PASSWORDLESS_LOGIN_LOADING'
export const USER_PASSWORDLESS_LOGIN_SUCCESS = 'USER_PASSWORDLESS_LOGIN_SUCCESS'
export const USER_PASSWORDLESS_LOGIN_ERROR = 'USER_PASSWORDLESS_LOGIN_ERROR'

export const loginPasswordless = (authToken) => {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_PASSWORDLESS_LOGIN_LOADING
    })
    // Validate session token on client before trying to login
    try {
      await Parse.Cloud.run('currentUser')
    } catch (e) {
      if (e.code === Parse.Error.INVALID_SESSION_TOKEN) await dispatch(logout())
    }
    return Parse.Cloud.run('passwordless_login', { token: authToken })
      .then((sessionToken) => {
        if (sessionToken) {
          return Parse.User.become(sessionToken).then((user) => {
            dispatch({
              type: USER_PASSWORDLESS_LOGIN_SUCCESS,
              payload: user
            })
            analyticsLogin('Passwordless', user.id, user)
            dispatch(afterLoggedInHook(user))
            return user
          })
        } else {
          // This means that the user that tried to authenticate was already logged in.
          // Just refreshing user data and redirecting.
          const user = Parse.User.current()
          dispatch({
            type: USER_PASSWORDLESS_LOGIN_SUCCESS,
            payload: user
          })
          dispatch(afterLoggedInHook(user))
          return Promise.resolve(user)
        }
      })
      .catch((error) => {
        const validationError =
          error?.message?.type === 'ValidationError' &&
          ['expired', 'alreadyUsed'].includes(error.message.message.token)

        const invalidParamsError =
          error?.message?.code === 'INVALID_PARAMS' &&
          ['expired', 'alreadyUsed'].includes(error.message.details.token)

        if (validationError || invalidParamsError) {
          const nextPathname = window.location.pathname
          const nextQuery = window.location.query
          const nextCleanQuery = omit(nextQuery, ['authToken'])
          dispatch({
            type: USER_PASSWORDLESS_LOGIN_ERROR,
            error: error
          })

          router.navigate({
            pathname: '/request-token',
            state: {
              nextRouterState: {
                pathname: nextPathname || window.location.pathname,
                query: nextCleanQuery
              }
            }
          })
        } else {
          dispatch({
            type: USER_PASSWORDLESS_LOGIN_ERROR,
            error
          })
        }
        captureException(error)
        return Promise.reject(error)
      })
  }
}

export const USER_PASSWORDLESS_REQUEST_TOKEN_LOADING = 'USER_PASSWORDLESS_REQUEST_TOKEN_LOADING'
export const USER_PASSWORDLESS_REQUEST_TOKEN_SUCCESS = 'USER_PASSWORDLESS_REQUEST_TOKEN_SUCCESS'

export const requestTokenPasswordless = ({
  email,
  nextRouterState,
  toastMessage,
  errorMessage
}) => {
  return (dispatch) => {
    dispatch({
      type: USER_PASSWORDLESS_REQUEST_TOKEN_LOADING
    })
    return Parse.Cloud.run('passwordless_requestNewAuthenticationToken', {
      email,
      nextRouterState
    })
      .then(() => {
        dispatch({
          type: USER_PASSWORDLESS_REQUEST_TOKEN_SUCCESS
        })
        if (toastMessage)
          dispatch(
            showToast({
              header: toastMessage.header,
              body: toastMessage.body,
              type: toastMessage.type
            })
          )
      })
      .catch(() =>
        dispatch(
          showToast({
            header: errorMessage.header,
            body: errorMessage.body,
            type: errorMessage.type
          })
        )
      )
  }
}

export const USER_ANONYMOUS_SIGNUP_LOADING = 'USER_ANONYMOUS_SIGNUP_LOADING'
export const USER_ANONYMOUS_SIGNUP_SUCCESS = 'USER_ANONYMOUS_SIGNUP_SUCCESS'

export const signupAnonymous = () => {
  return (dispatch) => {
    dispatch({
      type: USER_ANONYMOUS_SIGNUP_LOADING
    })
    return Parse.Cloud.run('passwordless_anonymousSignup', {
      region: process.env.REGION,
      abTestFraction: getABTestFraction()
    })
      .then((sessionToken) => Parse.User.become(sessionToken))
      .then((user) => {
        dispatch({
          type: USER_ANONYMOUS_SIGNUP_SUCCESS,
          payload: user
        })
        analyticsLogin('Passwordless', user.id, user)
        dispatch(afterLoggedInHook(user, { redirect: false, resetWishlist: false }))
        return user
      })
  }
}

export const USER_EXISTS = 'USER_EXISTS'
export const USER_DOES_NOT_EXIST = 'USER_DOES_NOT_EXIST'

export const verifyEmail = (email, nextRouterState) => {
  return (dispatch) => {
    return Parse.Cloud.run('verifyEmail', { email, nextRouterState })
      .then((userExists) => {
        dispatch({ type: userExists ? USER_EXISTS : USER_DOES_NOT_EXIST, email })
        return userExists
      })
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }
}

export const signUpForNewsletter = ({ email, dataUsageConsent, region }) =>
  Parse.Cloud.run('signUpForNewsletter', {
    email,
    dataUsageConsent: { ...dataUsageConsent, [PERMISSION_TYPE_EMAIL_MARKETING]: true },
    region
  })

export const signupForSellerService = ({ email, country }) => () =>
  Parse.Cloud.run('signupForSellerService', { email, country })
    .then((response) => response)
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })

export const IS_FIRST_TIME_MARKET_CUSTOMER_SUCCESS = 'IS_FIRST_TIME_MARKET_CUSTOMER_SUCCESS'
export const isFirstTimeMarketCustomer = () => (dispatch) => {
  const user = Parse.User.current()
  if (!user)
    return dispatch({
      type: IS_FIRST_TIME_MARKET_CUSTOMER_SUCCESS,
      isFirstTimeMarketCustomer: true
    })
  return runCloudCached((isFirstTimeMarketCustomer) => {
    dispatch({
      type: IS_FIRST_TIME_MARKET_CUSTOMER_SUCCESS,
      isFirstTimeMarketCustomer
    })
  })('isFirstTimeMarketCustomer')
}

export const getValidatedPhoneNumber = (phoneNumber) =>
  Parse.Cloud.run('validatePhoneNumber', { phoneNumber })

export const USER_DEMOGRAPHY_SCORE = 'USER_DEMOGRAPHY_SCORE'

export const getUserDemographyScore = () => async (dispatch) => {
  return runCloudCached((userDemographyScore) => {
    dispatch({ type: USER_DEMOGRAPHY_SCORE, userDemographyScore })
  })('getUserDemographyScore').catch(console.error)
}
