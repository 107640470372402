import { Button, ICONS, Icon, InfoBox } from '@sellpy/design-system-react-web'
import Parse from 'parse'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { DataLayer } from '../../../common/analytics/dataLayer.js'
import { setUserIdentificationInformation } from '../../../common/dac7/actions.js'
import { showToast } from '../../../common/ui/actions.js'
import { SIZES } from '../../uiComponents/constants.js'
import { P, SimpleModal } from '../../uiComponents/index.js'
import { Content } from '../../uiComponents/modal/styles.js'
import { TOAST_TYPE } from '../../uiComponents/notifications/Toasts.jsx'
import DAC7Form from './DAC7Form.jsx'
import DAC7Info from './DAC7Info.jsx'

const StyledModal = styled(SimpleModal)`
  ${Content} {
    padding: 16px;
  }
`

const DAC7 = ({
  openModalInfo,
  setOpenModalInfo,
  openModalForm,
  setOpenModalForm,
  showInfoBox = true
}) => {
  const { t } = useTranslation('balance')
  const user = Parse.User.current()
  const defaultCountry = user.get('address')?.country
  const dispatch = useDispatch()

  const openForm = () => {
    setOpenModalInfo(false)
    setOpenModalForm(true)
    DataLayer.trackDAC7OpenForm()
  }

  const closeForms = () => {
    setOpenModalInfo(false)
    setOpenModalForm(false)
  }

  const {
    control,
    formState: { errors, isValidating, isValid, dirtyFields, isSubmitting },
    handleSubmit,
    register,
    watch,
    trigger
  } = useForm({
    defaultValues: { country: defaultCountry, taxIdentificationCountry: defaultCountry },
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const onSubmit = (data) => {
    const {
      city,
      country,
      dateOfBirth,
      firstName,
      lastName,
      postalCode,
      streetAddress,
      taxIdentificationNumber,
      taxIdentificationCountry,
      confirmation
    } = data

    if (!confirmation) {
      return dispatch(
        showToast({
          header: t('balance:balanceOverview.dac7Modal.toast.error.header'),
          body: t('balance:balanceOverview.dac7Modal.toast.error.body'),
          type: TOAST_TYPE.ERROR
        })
      )
    }

    return dispatch(
      setUserIdentificationInformation(
        {
          city,
          country,
          dateOfBirth,
          firstName,
          lastName,
          postalCode,
          streetAddress,
          taxIdentificationNumber,
          taxIdentificationCountry
        },
        closeForms,
        t
      )
    )
  }

  return (
    <>
      {showInfoBox && (
        <div style={{ marginBottom: '16px' }}>
          <InfoBox
            headline={t('balanceOverview.dac7.infoBox.headline')}
            addon={
              <>
                <P design='body3'>{t('balanceOverview.dac7.infoBox.subHeadline')}</P>
                <ul style={{ marginTop: 0 }}>
                  <li>
                    <P design='body3'>{t('balanceOverview.dac7.infoBox.withdrawFunds')}</P>
                  </li>
                  <li>
                    <P design='body3'>{t('balanceOverview.dac7.infoBox.credits')}</P>
                  </li>
                </ul>
                <Button
                  color='white'
                  variant='filled'
                  type='button'
                  size={SIZES.SMALL}
                  label={t('balanceOverview.dac7.openModalInfo.button')}
                  onClick={() => {
                    setOpenModalInfo(!openModalInfo)
                    DataLayer.trackDAC7OpenInfo()
                  }}
                  disabled={openModalInfo || openModalForm}
                />
              </>
            }
            column={true}
          />
        </div>
      )}

      <StyledModal
        isOpen={openModalInfo || openModalForm}
        onClose={() => closeForms()}
        headline={t('balance:balanceOverview.dac7Modal.headline')}
        bottomCTA={
          <div>
            {openModalForm ? (
              <Button
                fullWidth
                label={t('balance:balanceOverview.dac7Modal.submitButton')}
                rightIcon={<Icon name={ICONS.PAPER_PLANE_FILLED} style={{ fontSize: '2rem' }} />}
                onClick={handleSubmit(onSubmit)}
                loading={isValidating || isSubmitting}
                disabled={!isValid || isValidating}
              />
            ) : (
              <Button
                fullWidth
                label={t('balanceOverview.dac7.openModalInfo.startForm')}
                onClick={openForm}
              />
            )}
          </div>
        }
      >
        {openModalForm ? (
          <>
            <div>
              <Button
                leftIcon={<Icon name={ICONS.ARROW_LEFT} />}
                label={t('balance:balanceOverview.dac7Modal.backButton.label')}
                variant='text'
                size={SIZES.SMALL}
                type='button'
                onClick={() => {
                  setOpenModalInfo(true)
                  setOpenModalForm(false)
                }}
              />
            </div>
            <DAC7Form
              setOpenModalForm={setOpenModalForm}
              setOpenModalInfo={setOpenModalInfo}
              control={control}
              errors={errors}
              register={register}
              watch={watch}
              trigger={trigger}
              dirtyFields={dirtyFields}
            />
          </>
        ) : (
          <DAC7Info />
        )}
      </StyledModal>
    </>
  )
}

export default DAC7
