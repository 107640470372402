import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { P } from '@sellpy/design-system-react-web'
import SubLabel from '../components/SubLabel.jsx'
import { transStringElementLabelValidation } from '../radioButton/RadioButton.jsx'

export const CHECKBOX_DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right'
}

// Determines colour of the checkbox label.
const _defaultLabelColor = ({ disabled, theme }) =>
  disabled ? theme.color.grey.shade6 : theme.color.grey.shade2

// Determines colour of the checkbox icon when checked.
export const defaultCheckedColor = ({ disabled, erroneous, theme }) =>
  disabled
    ? theme.color.grey.shade4
    : erroneous
    ? theme.color.red.default
    : theme.color.blue.default

// Shows the hover/focus circle element
export const hoverCircleEffect = ({ disabled, erroneous, theme }) => {
  return (
    !disabled &&
    `
    border-radius: 20px;
    background-color: ${theme.color.grey.shade8};

    &:not(:checked) {
      color: ${erroneous ? theme.color.red.default : theme.color.grey.shade3};
    }`
  )
}

const StyledLabel = styled(P)`
  color: ${_defaultLabelColor};
  width: ${({ direction }) => (direction === CHECKBOX_DIRECTIONS.RIGHT ? '100%' : 'auto')};
  padding: 8px 0px 8px 0px;
  a[href] {
    color: ${({ theme }) => theme.color.blue.default};
    margin: -8px 0px -8px 0px;
    padding: 8px 0px 8px 0px;
    display: inline-block;
  }
`

const StyledInput = styled.input.attrs({
  type: 'checkbox'
})`
  --icon-check-box: '\\E841';
  --icon-check-box-checked-filled: '\\E83D';
  appearance: none;
  border: none;
  width: 40px;
  height: 40px;
  background-color: transparent;
  font-size: 24px;
  line-height: 24px;
  align-self: center;
  font-family: 'fontello';
  margin: 0;
  ${({ direction }) =>
    direction === CHECKBOX_DIRECTIONS.RIGHT ? 'margin-right: -8px;' : 'margin-left: -8px;'}
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  color: ${({ erroneous, theme }) =>
    erroneous ? theme.color.red.default : theme.color.grey.shade6};

  ::before {
    /* Checkbox icon */
    content: var(--icon-check-box);
    position: relative;
    visibility: visible;
    top: 8px;
    padding: 8px;
  }

  &:checked {
    color: ${defaultCheckedColor};
    ::before {
      /* Checkbox icon */
      content: var(--icon-check-box-checked-filled
    );
    }
  }

  &:focus-visible,
  &:active
  {
    outline: none;
    ${hoverCircleEffect}
  }
`

const CheckboxWrapper = styled.label`
  position: relative;
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  align-items: stretch;
  min-height: 40px;

  ${({ direction }) =>
    direction === CHECKBOX_DIRECTIONS.RIGHT
      ? `
        width: auto;
        flex-direction: row-reverse;
      `
      : `
        width: fit-content;
        flex-direction: row;
      `}

  -webkit-tap-highlight-color: transparent;
  user-select: none;

  @media (hover: hover) {
    :hover {
      :not(:has(a:hover)) {
        input {
          ${hoverCircleEffect}
        }
      }
      @supports not selector(: has(a: hover)) {
        /* if :has isn't officially supported (https://caniuse.com/css-has) 
           we show the hover effect even when hovering over links 
        */
        input {
          ${hoverCircleEffect}
        }
      }
    }
  }
`

export const CheckboxSubLabel = styled(SubLabel)`
  padding: 0;
  ${({ direction }) =>
    direction === CHECKBOX_DIRECTIONS.RIGHT ? 'width: auto;' : 'width: fit-content;'}
`

const CheckboxComponent = (
  {
    value,
    checked,
    label,
    disabled,
    direction,
    name,
    onChange,
    onClickLabel,
    onBlur,
    isPartOfGroup = false,
    errors,
    helpText
  },
  ref
) => {
  return (
    <div>
      <CheckboxWrapper
        disabled={disabled}
        direction={direction}
        erroneous={Boolean(get(errors, name))}
      >
        <StyledInput
          ref={ref}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          checked={checked}
          erroneous={Boolean(get(errors, name))}
          direction={direction}
          disabled={disabled}
        />
        <StyledLabel
          onClick={onClickLabel}
          design='body1'
          noMargin
          disabled={disabled}
          direction={direction}
        >
          {label['label'] || label}
        </StyledLabel>
      </CheckboxWrapper>
      {!isPartOfGroup && (helpText || Boolean(errors)) && (
        <CheckboxSubLabel
          errors={errors}
          name={name}
          disabled={disabled}
          direction={direction}
          label={helpText}
        />
      )}
    </div>
  )
}

const Checkbox = React.forwardRef(CheckboxComponent)
Checkbox.displayName = CheckboxComponent.name
Checkbox.propTypes = {
  label: transStringElementLabelValidation,
  value: (
    // value can simply be true/false if the checkbox is standalone
    props,
    propName,
    componentName
  ) =>
    PropTypes.checkPropTypes(
      {
        [propName]: props['isPartOfGroup'] ? PropTypes.string.isRequired : PropTypes.any
      },
      props,
      propName,
      componentName
    ),
  name: PropTypes.string
}

export default Checkbox
