import React from 'react'
import styled, { useTheme } from 'styled-components'
import { ICONS, Icon } from '@sellpy/design-system-react-web'
import { H3, Span } from '../../../uiComponents'
import { getFormattedCount } from './utils.js'

export const Count = ({ count }) => {
  const style = {
    count: {
      margin: '0',
      padding: '0 0.5em',
      backgroundColor: 'white',
      color: '#888888',
      fontSize: '9px',
      lineHeight: '1.5rem'
    }
  }

  const formattedCount = getFormattedCount(count)

  return <Span style={style.count}>{formattedCount}</Span>
}

const StyledInput = styled.input`
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
  border: 0;
  outline: none;
  -webkit-appearance: none;
  font-size: 13px;
  width: 100%;
  background-color: transparent;
  height: 40px;
`

const SearchBoxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: ${({ theme }) => `1px ${theme.color.grey.default} solid`};
  margin-bottom: 1rem;
`

const SearchBoxContainerRoundEdges = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px 8px;
  background-color: ${({ theme }) => theme.color.grey.shade9};
  padding: 0 16px;
  border-radius: 104px;
`

export const SearchBox = ({ placeholder, onChange, query, translations, variant = 'default' }) => {
  const theme = useTheme()

  const pickVariant = () =>
    ({
      default: {
        container: SearchBoxContainer
      },
      roundEdges: {
        container: SearchBoxContainerRoundEdges
      }
    }[variant])

  const selectedVariant = pickVariant()

  return (
    <selectedVariant.container>
      <div style={{ cursor: 'pointer', marginRight: '1rem' }} title={translations.submitTitle}>
        <Icon name={ICONS.SEARCH} style={{ fontSize: '2rem', color: theme.color.grey.shade2 }} />
      </div>
      <StyledInput
        type='search'
        onChange={({ target }) => {
          onChange(target.value)
        }}
        onKeyPress={({ key, target }) => {
          if (key === 'Enter') {
            onChange(query)
            target.blur()
          }
        }}
        placeholder={placeholder}
        value={query}
      />
      {query.length > 0 && (
        <div
          style={{ cursor: 'pointer', marginLeft: 'auto' }}
          onClick={() => {
            onChange('')
          }}
          title={translations.resetTitle}
        >
          <Icon name={ICONS.CLOSE_CIRCLE_FILLED} style={{ fontSize: '1.5rem' }} />
        </div>
      )}
    </selectedVariant.container>
  )
}

export const SidebarHeader = ({ title, style }) => {
  return (
    <H3 design={'body1'} style={style}>
      {title}
    </H3>
  )
}

const Fade = () => {
  return (
    <div
      style={{
        display: 'block',
        bottom: '20px',
        height: '25px',
        width: '90%',
        backgroundImage:
          '-webkit-linear-gradient(bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.1) 100%)',
        position: 'absolute'
      }}
    />
  )
}

export const HierarchicalItemList = ({ showMore, ...rest }) => {
  return (
    <ItemList
      {...rest}
      style={{
        maxHeight: showMore ? 'none' : '215px',
        overflowY: 'auto',
        paddingBottom: showMore ? '5px' : '15px'
      }}
    />
  )
}

export const ItemList = ({ children, showMore, scrollRef, showFade, style }) => {
  return (
    <>
      <div
        style={
          style
            ? { ...style }
            : {
                maxHeight: showMore ? '225px' : 'none',
                overflowY: 'auto',
                paddingBottom: '6px'
              }
        }
        ref={scrollRef}
        // className='ais-RefinementList'
      >
        {children}
        {showMore && <div style={{ height: '12px' }} />}
      </div>
      {showFade && <Fade />}
    </>
  )
}
