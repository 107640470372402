import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { imageTools } from '@sellpy/commons'

const HoverImage = ({ primaryImage, secondaryImage, style, className, darken, ...props }) => {
  const [hovering, setIsHovering] = useState(false)
  const imageToShow = hovering ? secondaryImage || primaryImage : primaryImage
  return (
    <img
      loading='lazy'
      src={imageTools.optimizeImage(imageToShow, { width: 400 })}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={className}
      style={{
        display: 'block',
        width: '100%',
        height: 'auto',
        filter: darken && imageTools.isDarkenableImage(imageToShow) ? 'brightness(0.98)' : 'none',
        aspectRatio: '1/1',
        ...style
      }}
      {...props}
    />
  )
}

HoverImage.propTypes = {
  primaryImage: PropTypes.string.isRequired,
  secondaryImage: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string
}

export default HoverImage
