import React from 'react'
import styled from 'styled-components'
import { P } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../../../../../../uiComponents/mediaQueries'

export const SuggestionListContainer = styled.li`
  padding-bottom: 8px;
  ${mediaQueries.tablet} {
    padding-bottom: 4px;
  }
`

const List = styled.ul`
  list-style-type: 'none';
  margin: 0;
  padding: 0;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 100%;
`

export const SuggestionListHeader = styled(P).attrs({
  design: 'body3'
})`
  color: ${({ theme }) => theme.color.grey.shade3};
  margin: 8px 16px ${({ $isChipList }) => ($isChipList ? '12px' : '')};

  ${mediaQueries.tablet} {
    margin-bottom: ${({ $isChipList }) => ($isChipList ? '12px' : '4px')};
  }
`

export const SuggestionList = ({ title, getListProps, children }) => {
  return (
    <SuggestionListContainer>
      {<SuggestionListHeader>{title}</SuggestionListHeader>}
      <List {...getListProps()}>{children}</List>
    </SuggestionListContainer>
  )
}
