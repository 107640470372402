import { List } from 'immutable'

export const ENTITY_CLASSNAMES = List([
  '_User',
  'Auction',
  'Bag',
  'BagRating',
  'BagStatus',
  'BalanceTransaction',
  'BuyItNow',
  'CityCoverage',
  'ClaimShipment',
  'Compensation',
  'Container',
  'ContainerComment',
  'ContainerSubscription',
  'ContainerPickup',
  'Credit',
  'CreditTransaction',
  'CustomerImpersonation',
  'Delivery',
  'EbayOffer',
  'EmailLog',
  'FreightAlternative',
  'GiftCard',
  'GiftCardOrder',
  'Imbursement',
  'InventoryLog',
  'Item',
  'ItemActivity',
  'ItemCategory',
  'ItemClaim',
  'ItemChangeRequest',
  'ItemDescriptionChange',
  'ItemRephotography',
  'ItemDonation',
  'ItemFavoriteList',
  'ItemFeedback',
  'ItemIO',
  'ItemPackaging',
  'ItemBrand',
  'ItemRecommendationsForUser',
  'ItemReservation',
  'ItemType',
  'ItemValuation',
  'ItemWishlist',
  'LoyaltyProgramPeriod',
  'MarketInventory',
  'MarketOffer',
  'Notification',
  'Order',
  'OrderComment',
  'OrderRating',
  'OrderStatus',
  'P2pItemBuyRequest',
  'ParcelStatusAlert',
  'Payment',
  'PayOut',
  'Picklist',
  'PromoCode',
  'Referral',
  'RejectReason',
  'SaleRequest',
  'SearchProfile',
  'SendBackRequest',
  'SellerItemPricingPeriod',
  'Team',
  'TrackedSearch',
  'TrackedSearchActivity',
  'TraderaAlias',
  'TraderaShopItem',
  'P2pSellerReputation',
  'ContainerOrder',
  'UserSaleReport',
  'UserIdentificationInformation',
  'BankAccount'
])
