import { gql } from '@apollo/client'
import { locale } from '@sellpy/commons'
const { localizedMetadata } = locale

export const createItemFragment = (locale) => {
  const metadata = localizedMetadata(locale)

  return gql`
    fragment itemFragment on SaleItem {
      item {
        objectId
        itemIO {
          objectId
          titleOutputOrder {
            ... on Element {
              value
            }
          }
        }
        ${metadata}
      }
    }
  `
}

export const marketInventoryFragment = gql`
  fragment marketInventoryFragment on SaleItem {
    marketInventory {
      objectId
      value
      createdAt
    }
  }
`

export const balanceTransactionFragment = gql`
  fragment balanceTransactionFragment on SaleItem {
    itemSoldBalanceTransaction {
      delta
    }
  }
`

export const photosFragment = gql`
  fragment photosFragment on SaleItem {
    photos {
      url
    }
  }
`

export const pageInfoFragment = gql`
  fragment pageInfoFragment on SaleItemConnection {
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`
