import { useMemo, useState } from 'react'
import { createAutocomplete } from '@algolia/autocomplete-core'
import { getAlgoliaFacets } from '@algolia/autocomplete-preset-algolia'
import { itemCategoryMapper, searchUrls } from '@sellpy/commons'
import { useNavigate } from 'react-router-dom'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { inRegionalMarket } from '../../../../../search/regionalFilters.js'
import {
  ALGOLIA_SORT_INDEX,
  algoliaClients,
  AUTOCOMPLETE_INDEX,
  BRAND_INDEX,
  getAlgoliaClient,
  STRUCTURED_SEARCH_INDEX
} from '../../../../../../common/lib/algolia.js'
import { analyticsSearch } from '../../../../../../common/analytics/actions.js'
import { locale } from '../../../../../../common/region/locale.js'
import {
  DISPLAYED_LATEST_SEARCHES_NUMBER,
  getLatestSearches
} from '../../../../../../common/localStorage.js'
import { region } from '../../../../../../common/region/region.js'

const algoliaClient = getAlgoliaClient()

const categoryFacetQuery = ({ query, maxFacetHits, baseCategory }) => ({
  indexName: ALGOLIA_SORT_INDEX.relevance,
  facet: 'searchableExternalCategory',
  params: {
    facetQuery: query,
    maxFacetHits: maxFacetHits,
    filters: `${inRegionalMarket}` + (baseCategory ? ` AND categories.lvl0:${baseCategory}` : '')
  }
})

export const useAutocomplete = () => {
  const navigate = useNavigate()
  const baseCategory = itemCategoryMapper.baseCategories(locale)

  const [autocompleteState, setAutocompleteState] = useState({})

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          setAutocompleteState(state)
        },
        openOnFocus: true,
        navigator: {
          navigate({ itemUrl, item, ...rest }) {
            analyticsSearch({
              searchTerm: item.query || item.label || item.brand,
              searchSource: itemUrl.source
            })
            navigate(itemUrl.url)
          }
        },
        getSources() {
          return [
            {
              sourceId: 'querySuggestions',
              getItemInputValue({ item }) {
                return item.query
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient: algoliaClient,
                  queries: [
                    {
                      indexName: AUTOCOMPLETE_INDEX,
                      query,
                      params: {
                        hitsPerPage: 9
                      }
                    }
                  ]
                })
              },
              getItemUrl({ item }) {
                return {
                  source: 'querySuggestions',
                  url: searchUrls.stringifySearchStateV2(region())({
                    query: item.query
                  })
                }
              }
            },
            {
              sourceId: 'latestSearches',
              getItems: ({ query }) => {
                if (query.length) return []
                return getLatestSearches().slice(0, DISPLAYED_LATEST_SEARCHES_NUMBER)
              },
              getItemUrl({ item }) {
                return {
                  source: 'latestSearches',
                  url: item.path
                }
              }
            },
            {
              sourceId: 'brands',
              getItems: ({ query }) => {
                if (!query.length) return []
                return getAlgoliaResults({
                  searchClient: algoliaClients.nonItem,
                  queries: [
                    {
                      indexName: BRAND_INDEX,
                      query,
                      params: {
                        hitsPerPage: 2,
                        filters: 'freq > 1000',
                        typoTolerance: false
                      }
                    }
                  ]
                })
              },
              getItemUrl({ item }) {
                return {
                  source: 'brands',
                  url: searchUrls.stringifySearchStateV2(region())({
                    refinementList: { brand: [item.brand] }
                  })
                }
              },
              getItemInputValue({ item }) {
                return item.brand
              }
            },
            {
              sourceId: 'categories',
              getItems: ({ query }) => {
                if (!query.length) return []
                return getAlgoliaFacets({
                  searchClient: algoliaClient,
                  queries: [
                    categoryFacetQuery({ query, maxFacetHits: 2 }),
                    categoryFacetQuery({
                      query,
                      maxFacetHits: 1,
                      baseCategory: baseCategory.women
                    }),
                    categoryFacetQuery({
                      query,
                      maxFacetHits: 1,
                      baseCategory: baseCategory.men
                    }),
                    categoryFacetQuery({
                      query,
                      maxFacetHits: 1,
                      baseCategory: baseCategory.children
                    })
                  ],
                  transformResponse: ({ facetHits }) => {
                    const category0InResult = new Set()
                    const filteredFacets = facetHits.map((hits, index) => {
                      return hits.filter((hit) => {
                        const hitCat0 = hit.label.split(' > ')[0]
                        const isCat0AlreadyInResult = category0InResult.has(hitCat0)
                        category0InResult.add(hitCat0)
                        return (
                          hit.count > 0 &&
                          (index === 0 || !isCat0AlreadyInResult) &&
                          category0InResult.size <= 3
                        )
                      })
                    })
                    return filteredFacets
                  }
                })
              },
              getItemUrl({ item }) {
                return {
                  source: 'categories',
                  url: searchUrls.stringifySearchStateV2(region())({
                    hierarchicalMenu: { 'categories.lvl0': item.label.split(' > ') }
                  })
                }
              },
              getItemInputValue({ item }) {
                const categorySplit = item.label.split(' > ')
                return categorySplit[categorySplit.length - 1]
              }
            },
            {
              sourceId: 'structuredSearchQuerySuggestions',
              getItems: ({ query }) => {
                if (!query.length) return []
                return getAlgoliaResults({
                  searchClient: algoliaClient,
                  queries: [
                    {
                      indexName: STRUCTURED_SEARCH_INDEX,
                      query,
                      params: {
                        hitsPerPage: 2,
                        filters: `(type:userStore OR type:curatedStore OR type:selectionStore OR type:collectionStore) AND (region:${process.env.REGION})`
                      }
                    }
                  ]
                })
              },
              getItemInputValue({ item }) {
                return item.query
              },
              getItemUrl({ item }) {
                return {
                  source: 'structuredSearchQuerySuggestions',
                  url: item.url.startsWith('/') ? item.url : `/${item.url}`
                }
              }
            }
          ]
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return { autocomplete, autocompleteState }
}
