import styled from 'styled-components'

export const ToggleInput = styled.div`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export const ToggleCheck = styled.div`
  position: absolute;
  width: 22px;
  height: 22px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  transition: opacity 0.25s ease;
  left: 4px;
  span {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }
`

export const Container = styled.div`
  width: ${({ v2 }) => (v2 ? '54px' : '50px')};
  height: ${({ v2 }) => (v2 ? '32px' : '24px')};
  padding: 0;
  border-radius: 999px;
  // Special green color not in theme
  background-color: ${({ theme, checked, v2 }) =>
    checked ? (v2 ? theme.color.blue.default : '#19AB27') : theme.color.grey.shade6};
  transition: all 0.2s ease;
`

export const ToggleCircle = styled.div`
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: ${({ v2 }) => (v2 ? '2px' : '1px')};
  left: ${({ checked, v2 }) => (checked ? (v2 ? '24px' : '27px') : v2 ? '2px' : '1px')};
  width: ${({ v2 }) => (v2 ? '28px' : '22px')};
  height: ${({ v2 }) => (v2 ? '28px' : '22px')};
  // Special green color not in theme
  border: ${({ theme, checked, v2 }) =>
    v2 ? 'none' : `1px solid ${checked ? '#19AB27' : theme.color.grey.shade2}`};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.grey.shade10};
  box-sizing: border-box;
  transition: all 0.25s ease;
`

export const StyledToggle = styled.div`
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  `}
  :hover {
    ${Container} {
      background-color: ${({ disabled, theme, checked, v2 }) =>
        // Special green color not in theme
        !disabled && !v2 && (checked ? '#128D15' : theme.color.grey.shade1)};
    }
    ${ToggleCircle} {
      border: ${({ disabled, theme, checked, v2 }) =>
        // Special green color not in theme
        v2 ? 'none' : `1px solid ${!disabled && (checked ? '#128D15' : theme.color.grey.shade3)}`};
    }
  }
`
