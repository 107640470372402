import styled from 'styled-components'
import { mediaQueries } from '../uiComponents/mediaQueries'

export const StoreGrid = styled.div`
  margin: 8px 0;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-row-gap: 8px;
  grid-template-areas:
    'bc bc bc bc bc bc bc bc bc bc bc bc'
    'hr hr hr hr hr hr hr hr hr hr hr hr'
    'id id id id id id id id id id id id'
    'rs rs rs rs rs rs rs rs rs rs rs rs'
    'pg pg pg pg pg pg pg pg pg pg pg pg';
  grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, auto) auto minmax(0, auto);
  ${mediaQueries.largerThanTablet} {
    ${({ theme }) => theme.layout.contentPadding};
    margin: 16px 0;
    grid-column-gap: 16px;
    grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, auto) minmax(0, auto) auto auto;
    grid-template-areas:
      'lc lc lc bc bc bc bc bc bc bc bc bc'
      'lc lc lc hr hr hr hr hr hr hr hr hr'
      'lc lc lc hf hf hf hf hf hf hf hf hf'
      'lc lc lc id id id id id id id id id'
      'lc lc lc rs rs rs rs rs rs rs rs rs'
      'lc lc lc pg pg pg pg pg pg pg pg pg';
  }
  ${mediaQueries.largeDesktop} {
    margin: 24px 0;
    grid-template-areas:
      'lc lc bc bc bc bc bc bc bc bc bc bc'
      'lc lc hr hr hr hr hr hr hr hr hr hr'
      'lc lc hf hf hf hf hf hf hf hf hf hf'
      'lc lc id id id id id id id id id id'
      'lc lc rs rs rs rs rs rs rs rs rs rs'
      'lc lc pg pg pg pg pg pg pg pg pg pg';
  }
  .item-tile-container {
    grid-column: span 6;
    ${mediaQueries.largerThanTablet} {
      grid-column: span 3;
    }
    ${mediaQueries.largeDesktop} {
      grid-column: span 2;
    }
  }
`

export const LeftColumnDiv = styled.div`
  grid-area: lc;
`

export const BreadCrumbsDiv = styled.div`
  ${mediaQueries.tablet} {
    ${({ theme }) => theme.layout.contentPadding};
  }
  margin-left: -12px;
  overflow-x: scroll;
  grid-area: bc;
  margin-right: 8px;
`

export const HeaderDiv = styled.div`
  grid-area: hr;
  margin-bottom: 16px;
  ${mediaQueries.tablet} {
    border-bottom: 1px solid ${({ theme }) => theme.color.grey.shade7};
    margin-bottom: 8px;
  }
`

export const StyledBorder = styled.hr`
  width: 100%;
  border-top: ${({ theme }) => theme.color.grey.shade7};
  margin: 0;
`

export const SearchActionsContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, calc(50% - 1px));
  border: ${({ theme }) => `1px solid ${theme.color.grey.shade7}`};
  border-style: solid none;
  > :first-child {
    border: none;
    border-right: ${({ theme }) => `1px solid ${theme.color.grey.shade7}`};
  }
`

export const RefinementsContainer = styled.div`
  display: flex;
  align-content: space-between;
  gap: 24px;
  margin-top: ${({ isInQuickFiltersTest }) => (isInQuickFiltersTest ? 4 : 8)}px;
`

export const IndicatorsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: id;
`

export const ResultsDiv = styled.div`
  ${mediaQueries.tablet} {
    ${({ theme }) => theme.layout.contentPadding};
  }
  grid-area: rs;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  ${mediaQueries.largerThanTablet} {
    grid-template-columns: repeat(9, [col-start] 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  ${mediaQueries.largeDesktop} {
    grid-template-columns: repeat(10, [col-start] 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
`
export const HorizontalFiltersDiv = styled.div`
  grid-area: hf;
`

export const SearchSortIndicatorsDiv = styled.div`
  display: flex;
  align-items: center;

  ${mediaQueries.tablet} {
    justify-content: space-between;
    padding: 0 16px;
  }

  ${mediaQueries.phone} {
    padding: 0 8px;
  }
`

export const PaginationDiv = styled.div`
  ${mediaQueries.tablet} {
    ${({ theme }) => theme.layout.contentPadding};
  }
  grid-area: pg;
`
