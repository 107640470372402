import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Icon, P } from '@sellpy/design-system-react-web'
import styled, { useTheme } from 'styled-components'
import { mediaQueries } from '../../../../../../uiComponents/mediaQueries.js'
import { analyticsSearch } from '../../../../../../../common/analytics/actions.js'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 1px 16px;
  ${mediaQueries.tablet} {
    padding: 5px 16px; // Line height of the icon is 30px, so 5px padding makes the height 40px.
  }
`

const Item = styled.li`
  display: flex;
  z-index: 2500;
  :hover {
    text-decoration: none;
  }
  &[aria-selected='true'] {
    ${Container} {
      background-color: ${({ theme }) => theme.color.grey.shade9};
      text-decoration: none;
    }
    // Ugly solution to emulate hover effect on selection for chips.
    button {
      background-color: ${({ theme }) => theme.color.blue.shade9};
      border-color: ${({ theme }) => theme.color.blue.shade9};
    }
  }
`

const Link = styled(RouterLink)`
  width: 100%;
  box-sizing: border-box;
  ::first-letter {
    text-transform: capitalize;
  }
  :hover {
    text-decoration: none;
  }
`

const Title = styled(P).attrs({ design: 'body1', noMargin: true })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SubTitle = styled(P).attrs({ design: 'body3', noMargin: true })`
  color: ${({ theme }) => theme.color.grey.shade3};
  flex: 1;
  text-align: right;
`

export const SuggestionItem = ({ icon, title, subTitle, getItemProps, item, source, children }) => {
  const theme = useTheme()

  return (
    <Item
      {...getItemProps({
        item,
        source
      })}
    >
      <Link
        to={source.getItemUrl({ item }).url}
        onClick={() => {
          analyticsSearch({
            searchTerm: item.query || item.label || item.brand,
            searchSource: source.sourceId
          })
        }}
      >
        {children || (
          <Container>
            <Icon name={icon} style={{ fontSize: '24px', color: theme.color.grey.shade5 }} />
            <Title>{title}</Title>
            <SubTitle>{subTitle}</SubTitle>
          </Container>
        )}
      </Link>
    </Item>
  )
}
