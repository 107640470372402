import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from '@sellpy/design-system-react-web'
import { region as regionFunctions } from '@sellpy/commons'
import { H2, P, A } from '../uiComponents'
import { getDataUsageConsentForUser, setDataUsageConsentForUser } from '../../common/user/actions'
import {
  PERMISSION_TYPE_ANALYTICS,
  PERMISSION_TYPE_EXTERNAL_MARKETING
} from '../../common/user/dataHandling'
import { cookieSettingsModalOpen } from '../../common/ui/actions'
import { mediaQueries } from '../uiComponents/mediaQueries'

const Wrapper = styled.div`
  padding: 2rem;
  border-radius: 1rem 1rem 0 0;
  background-color: ${({ theme }) => theme.color.grey.shade8};
  ${mediaQueries.largerThanTablet} {
    padding: 3rem;
    border-radius: 1rem;
  }
`

const Position = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1100;
  max-width: 450px;
  ${mediaQueries.largerThanTablet} {
    left: 30px;
    bottom: 30px;
  }
`

const Paragraph = styled(P)`
  & a {
    line-height: inherit;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1099;
  background-color: rgba(0, 0, 0, 0.5);
`

const dataUsageConsentLoadedSelector = (state) => state.user.dataUsageConsentLoaded
const dataUsageConsentSelector = (state) => state.user.dataUsageConsent

export const DataConsentBanner = () => {
  const dispatch = useDispatch()
  const dataUsageConsentLoaded = useSelector(dataUsageConsentLoadedSelector)
  const dataUsageConsent = useSelector(dataUsageConsentSelector)

  const { t } = useTranslation('general')
  if (!dataUsageConsentLoaded || dataUsageConsent.get(PERMISSION_TYPE_ANALYTICS) != null) {
    return null
  }

  const activeAnalyticsConsent = Boolean(dataUsageConsent.get(PERMISSION_TYPE_ANALYTICS))
  const activeExternalMarketingConsent = Boolean(
    dataUsageConsent.get(PERMISSION_TYPE_EXTERNAL_MARKETING)
  )

  const reloadIfRequired = ({ analyticsConsent, marketingConsent }) => {
    if (
      (analyticsConsent != null && Boolean(analyticsConsent) === false && activeAnalyticsConsent) ||
      (marketingConsent != null &&
        Boolean(marketingConsent) === false &&
        activeExternalMarketingConsent)
    ) {
      window.location.reload()
    }
  }

  return (
    <>
      <Overlay />
      <Position>
        <Wrapper>
          <H2>{t('dataUsageBanner.headline')}</H2>
          <Paragraph design={'body5'}>{t('dataUsageBanner.body1')}</Paragraph>
          <Paragraph design={'body5'}>
            <Trans i18nKey={'general:dataUsageBanner.body2'}>
              part <A href={regionFunctions.cookiePolicyLink(process.env.REGION)}>part1</A>part2
            </Trans>
          </Paragraph>
          <div>
            <Button
              style={{ marginTop: 8 }}
              fullWidth
              color='blue'
              label={t('dataUsageBanner.acceptAll')}
              onClick={() => {
                dispatch(
                  setDataUsageConsentForUser({
                    type: PERMISSION_TYPE_ANALYTICS,
                    consent: true
                  })
                )
                dispatch(
                  setDataUsageConsentForUser({
                    type: PERMISSION_TYPE_EXTERNAL_MARKETING,
                    consent: true
                  })
                )
                dispatch(getDataUsageConsentForUser({}, { cacheBust: true }))
              }}
            />
            <Button
              label={t('dataUsageBanner.acceptNecessaryOnly')}
              variant='outlined'
              color='blue'
              fullWidth
              onClick={async () => {
                await Promise.all([
                  dispatch(
                    setDataUsageConsentForUser({
                      type: PERMISSION_TYPE_ANALYTICS,
                      consent: false
                    })
                  ),
                  dispatch(
                    setDataUsageConsentForUser({
                      type: PERMISSION_TYPE_EXTERNAL_MARKETING,
                      consent: false
                    })
                  )
                ])
                reloadIfRequired({
                  analyticsConsent: false,
                  marketingConsent: false
                })
              }}
              style={{ marginTop: 8 }}
            />
            <Button
              label={t('dataUsageBanner.cookieSettings')}
              variant='outlined'
              color='blue'
              fullWidth
              onClick={() => dispatch(cookieSettingsModalOpen(true))}
              style={{ marginTop: 8 }}
            />
          </div>
        </Wrapper>
      </Position>
    </>
  )
}
