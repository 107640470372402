import React from 'react'
import { useTranslation } from 'react-i18next'
import { P } from '@sellpy/design-system-react-web'
import styled from 'styled-components'
import { SimpleModal } from '../../uiComponents'
import DonationSvg from './DonationSvg.jsx'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const CharityInformationModal = ({ isOpen, setIsOpen, organizations }) => {
  const { t } = useTranslation(['balance', 'requestPayout'])
  const onClose = () => setIsOpen(false)
  return (
    <SimpleModal headline={t('charity.info.headline')} isOpen={isOpen} onClose={onClose}>
      <Container>
        {Object.keys(organizations).map((key) => (
          <div key={key}>
            <DonationSvg name={key} />
            <P design='body3' noMargin>
              {t(`requestPayout:form.donationInformation.${key}`)}
            </P>
          </div>
        ))}
      </Container>
    </SimpleModal>
  )
}

export default CharityInformationModal
