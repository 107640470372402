import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ICONS, Icon, Button, FixedImage } from '@sellpy/design-system-react-web'
import LazyLoad from '../../../components/LazyLoad.jsx'
import { useResponsive } from '../../../hooks'
import { H1, SIZES, P, Span } from '../../index'
import { mediaQueries } from '../../mediaQueries.js'
import { inAbTestTestGroup } from '../../../../common/analytics/abTest.js'

const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  ${mediaQueries.tablet} {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    display: block;
  }
`

const CircularWrapper = styled.div`
  margin-right: 24px;
  min-width: 72px;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  overflow: hidden;
  ${mediaQueries.tablet} {
    margin-right: 12px;
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
`

const ShowMoreButton = styled.button`
  display: flex;
  border: none;
  background: transparent;
  padding: 12px 0 0 0;
  text-decoration: underline;
  outline: none;
`

const TextWrapper = styled.div`
  overflow: ${({ showMore }) => (showMore ? 'auto' : 'hidden')};
  max-height: ${({ showMore }) => (showMore ? 'auto' : '64px')};
  -webkit-mask-image: ${({ gradient }) =>
    gradient ? 'linear-gradient(to top, transparent 2%, black 75%)' : 'unset'};
  mask-image: ${({ gradient }) =>
    gradient ? 'linear-gradient(to top, transparent 2%, black 75%)' : 'unset'};
`

const SearchHeader = ({ headline, description, src, alt, onClickTrackedSearch, blob }) => {
  const { isTablet } = useResponsive()
  const [showMore, setShowMore] = useState(false)
  const [overflow, setOverflow] = useState(false)
  const { t } = useTranslation('storePage')
  const isInQuickFiltersTest = inAbTestTestGroup('quickFilters')

  const ref = useRef()

  useEffect(() => {
    if (!ref?.current) return
    setOverflow(ref.current.clientHeight < ref.current.scrollHeight)
  }, [showMore, description])

  return (
    <>
      <WrapperDiv>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '24px', gap: '16px' }}>
          {src && (
            <CircularWrapper>
              <LazyLoad>
                <FixedImage src={src} alt={alt} width='70px' />
              </LazyLoad>
            </CircularWrapper>
          )}
          <H1 design={isTablet ? 'h4' : 'h2'} noMargin>
            {headline}
          </H1>
          {blob && blob}
        </div>
        {!isInQuickFiltersTest && !isTablet && onClickTrackedSearch && (
          <Button
            label={t('saveSearch')}
            onClick={onClickTrackedSearch}
            leftIcon={<Icon name={ICONS.BOOKMARK_ADD} />}
            variant='filled'
            color='grey'
            size={SIZES.SMALL}
          />
        )}
      </WrapperDiv>
      {description && (
        <TextWrapper ref={ref} showMore={showMore} gradient={overflow}>
          <P design='body3' style={{ margin: '12px 0 0 0' }}>
            {description}
          </P>
        </TextWrapper>
      )}
      {!overflow && !showMore ? null : (
        <ShowMoreButton onClick={() => setShowMore(!showMore)}>
          <Span design='body4'>{showMore ? t('bodyText.showLess') : t('bodyText.showMore')}</Span>
        </ShowMoreButton>
      )}
    </>
  )
}
export default SearchHeader
