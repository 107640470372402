import Parse from 'parse'
import { marketplace } from '@sellpy/commons'
import { cacheEntities } from '../entities/actions'
import { runCloudCached } from '../lib/parseTools'

export const initCreditInfo = () => (dispatch, getState) => {
  const before = new Date()
  dispatch(setBefore(before))
  return Parse.Cloud.run('creditInfo', {
    before,
    currency: marketplace.CURRENCY[process.env.REGION]
  }).then(({ credit, n }) => {
    dispatch(setCreditInfo(n)(credit))
  })
}

export const CREDIT_SET_CREDIT_INFO = 'CREDIT_SET_CREDIT_INFO'
const setCreditInfo = (n) => (credit) => ({
  type: CREDIT_SET_CREDIT_INFO,
  n,
  credit
})

export const CREDIT_SET_BEFORE = 'CREDIT_SET_BEFORE'
const setBefore = (before) => ({
  type: CREDIT_SET_BEFORE,
  before
})

export const fetchCreditTransactions = (before) => (skip) => async (dispatch, getState) => {
  const { transactions, orders } = await Parse.Cloud.run('completedCreditTransactions', {
    before,
    skip,
    currency: marketplace.CURRENCY[process.env.REGION]
  })

  dispatch(cacheEntities(orders))
  dispatch(cacheEntities(transactions))
}

export const fetchCreditTransactionPaymentForOrder = (orderId) => (dispatch, getState) =>
  Parse.Cloud.run('creditTransactionPaymentForMarketOrderPage', {
    id: orderId
  }).then((response) => {
    if (response) {
      const { transaction, payment } = response
      dispatch(cacheEntities(transaction))
      dispatch(cacheEntities(payment))
    }
  })

export const fetchCreditTransactionRefundsForOrder = (orderId) => (dispatch, getState) =>
  Parse.Cloud.run('creditTransactionRefundsMarket', { id: orderId }).then((transactions) =>
    dispatch(cacheEntities(transactions))
  )

export const fetchImbursementFromCreditTransaction = (creditTransactionId) => async (dispatch) =>
  runCloudCached((imbursement) => {
    dispatch(cacheEntities(imbursement))
  })('getImbursementForCreditTransaction', { creditTransactionId }).catch(console.error)
