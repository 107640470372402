import { Record } from 'immutable'
import * as actions from './actions'

const State = Record({
  loading: false
})

const initialState = new State()

export default function claimReducer(state = initialState, action) {
  switch (action.type) {
    case actions.INITIALIZE_NEW_CLAIM_PAGE_LOADING: {
      return state.setIn(['loading'], true)
    }

    case actions.INITIALIZE_NEW_CLAIM_PAGE_SUCCESS: {
      return state.setIn(['loading'], false)
    }

    case actions.INITIALIZE_NEW_CLAIM_PAGE_FAILURE: {
      return state.setIn(['loading'], false)
    }
  }
  return state
}
