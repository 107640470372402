import { region as regionTools } from '@sellpy/commons'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Button, ICONS, Icon } from '@sellpy/design-system-react-web'
import { useTranslation } from 'react-i18next'
import { RadioGroup, SIZES } from '../../uiComponents'
import { region } from '../../../common/region/region'
import CharityInformationModal from './CharityInformationModal.jsx'

const CharityOptions = () => {
  const { t } = useTranslation(['balance', 'common'])
  const [isOpen, setIsOpen] = useState(false)
  const organizations = regionTools.getOrganizationsForRegion(region())
  const charityOptions = Object.entries(organizations).map(([key, value]) => ({
    value: key,
    label: value.name
  }))
  const {
    register,
    formState: { errors }
  } = useFormContext()
  return (
    <div>
      <CharityInformationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        organizations={organizations}
      />
      <RadioGroup
        name={'donationOrganization'}
        register={register}
        registerOptions={{
          required: t('common:genericErrors.required')
        }}
        options={charityOptions}
        errors={errors}
      />
      <Button
        label={t('charity.readmore')}
        rightIcon={<Icon name={ICONS.INFORMATION} />}
        color={'blue'}
        variant={'text'}
        size={SIZES.SMALL}
        type={'button'}
        onClick={() => setIsOpen(true)}
      />
    </div>
  )
}

export default CharityOptions
