import {
  FEATURE_FASHIONWEEK_COLLAB_BUY,
  FEATURE_HM_COLLAB_SELL,
  FEATURE_KLARNA_COLLAB_BUY,
  FEATURE_SNAPCHAT_COLLAB_BUY,
  isFeatureEnabled
} from '../../client/featureToggle'

export const HM = 'hm'
export const FLATTERED = 'flattered'
export const KLARNA = 'klarna'
export const SNAPCHAT = 'snapchat'
export const FASHIONWEEK = 'fashionweek'

export const collabBrand = (collab) =>
  ({
    [HM]: 'H&M'
  }[collab])

export const collabIsValid = (collab) => {
  if (collab === HM && isFeatureEnabled(FEATURE_HM_COLLAB_SELL)) return true
  if (collab === KLARNA && isFeatureEnabled(FEATURE_KLARNA_COLLAB_BUY)) return true
  if (collab === SNAPCHAT && isFeatureEnabled(FEATURE_SNAPCHAT_COLLAB_BUY)) return true
  if (collab === FASHIONWEEK && isFeatureEnabled(FEATURE_FASHIONWEEK_COLLAB_BUY)) return true
  return false
}
