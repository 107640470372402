import React, { useCallback, useEffect, useRef, useState } from 'react'
import { imageTools } from '@sellpy/commons'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ICONS, Icon, P } from '@sellpy/design-system-react-web'
import {
  useClickOutsideMultipleElements,
  useHeaderHeight,
  useResponsive
} from '../../../hooks/index.js'
import { useSwipeable, SwipeableList, Dots, SwipeableWrapper } from '../../swipeableViews'
import { DataLayer } from '../../../../common/analytics/dataLayer.js'
import { CarouselContainer } from '../../../item/components/carousel/styles.js'
import { getImageLabel } from '../../../item/buyer/utils.js'
import { BrightnessAdjuster } from './brightnessAdjuster/BrightnessAdjuster.jsx'
import Arrows from './Arrows.jsx'
import {
  Cover,
  ImageWrapper,
  CrossWrapper,
  StyledFigure,
  DesktopInnerImg,
  ZoomToolTip,
  StyledDesktopImage,
  StyledMobileImage
} from './styles'
import { useIsBuyerPDP } from './hooks/useIsBuyerPDP.js'

const LightBox = ({ photos, alt, activeIndex, handleClose, deactivateZoomFunction, item }) => {
  const headerHeight = useHeaderHeight()
  const { isTablet } = useResponsive()
  const [activeImage, setActiveImage] = useState(activeIndex || 0)
  const showImage = true
  const ref = useRef()
  const crossRef = useRef()
  const arrowLeftRef = useRef()
  const arrowRightRef = useRef()
  const brightnessAdjusterRef = useRef()
  const { t } = useTranslation('item')

  const subTextOnImage = useCallback((photo) => getImageLabel({ photo, item, t }), [t, item])

  const handleKeyPress = useCallback(
    (event) => {
      switch (event.key) {
        case 'ArrowRight':
          if (activeImage === photos.size - 1) {
            setActiveImage(0)
          } else {
            setActiveImage(activeImage + 1)
          }
          break
        case 'ArrowLeft':
          if (activeImage === 0) {
            setActiveImage(photos.size - 1)
          } else {
            setActiveImage(activeImage - 1)
          }
          break
        case 'Escape':
          handleClose(false)
      }
    },
    [activeImage, photos, handleClose]
  )

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress, false)
    return () => {
      document.removeEventListener('keyup', handleKeyPress, false)
    }
  }, [handleKeyPress])

  useClickOutsideMultipleElements(
    [!isTablet && ref, crossRef, arrowLeftRef, arrowRightRef, brightnessAdjusterRef],
    () => handleClose(false)
  )

  const isBuyerPDP = useIsBuyerPDP()
  const [imageBrightness, setImageBrightness] = useState(100)
  const activeImageUrl = photos.get(activeImage)?.get('url')
  const isImageBrightnessAdjusterVisible =
    isBuyerPDP && activeImageUrl && imageTools.isBrightnessAdjustableImage(activeImageUrl)

  return (
    <Cover headerHeight={headerHeight} showImage={showImage}>
      <CrossComponent ref={crossRef} onClick={() => handleClose(false)} />
      <ImageWrapper ref={ref}>
        {isTablet ? (
          <MobileImage
            photos={photos}
            activeImage={photos.get(activeImage) ? activeImage : 0}
            alt={alt}
            subTextOnImage={subTextOnImage}
            imageBrightness={imageBrightness}
          />
        ) : (
          <DesktopImage
            photos={photos}
            activeImage={photos.get(activeImage) ? activeImage : 0}
            alt={alt}
            subTextOnImage={subTextOnImage}
            deactivateZoomFunction={deactivateZoomFunction}
            imageBrightness={imageBrightness}
          />
        )}
        <BrightnessAdjuster
          ref={brightnessAdjusterRef}
          isVisible={isImageBrightnessAdjusterVisible}
          imageBrightness={imageBrightness}
          setImageBrightness={setImageBrightness}
          itemId={item.get('objectID') || item.get('objectId')}
        />
      </ImageWrapper>
      {!isTablet && photos.size > 1 && (
        <>
          <Arrows
            ref={arrowLeftRef}
            direction='left'
            handleClick={() => handleKeyPress({ key: 'ArrowLeft' })}
          />
          <Arrows
            ref={arrowRightRef}
            direction='right'
            handleClick={() => handleKeyPress({ key: 'ArrowRight' })}
          />
        </>
      )}
    </Cover>
  )
}

export default LightBox

// eslint-disable-next-line react/display-name
const CrossComponent = React.forwardRef(({ onClick }, ref) => (
  <CrossWrapper ref={ref} onClick={onClick}>
    <Icon name={ICONS.CLOSE} style={{ color: 'white', fontSize: '2rem' }} />
  </CrossWrapper>
))

const ImageContainer = styled.li`
  div {
    width: 100%;
  }
`

const hasBeenZoomed = {}
const mobileZoomTracking = ({ photo }) => {
  if (!hasBeenZoomed[photo]) {
    DataLayer.trackImageZoomClick()
    hasBeenZoomed[photo] = photo
  }
}

const ImageDescription = styled(P)`
  position: absolute;
  top: 100%;
  width: 80%;
  padding: 0 10%;
`

const MobileImageDescription = styled(P)`
  margin: 0 auto;
`

const MobileImage = ({ photos, activeImage, alt, subTextOnImage, imageBrightness }) => {
  const [zoomedOut, setZoomedOut] = useState(true)
  const wrapperRef = useRef()
  const entriesRef = useRef({})
  const { activeIndex } = useSwipeable({ wrapperRef, entriesRef })

  const specialImageInfo =
    subTextOnImage && photos.get(activeIndex) && subTextOnImage(photos.get(activeIndex))

  const handleOnPinchingStop = useCallback(({ ev, photo }) => {
    mobileZoomTracking({ photo })
    if (ev.scale <= 1) {
      setZoomedOut(true)
    } else {
      setZoomedOut(false)
    }
  }, [])

  return (
    <SwipeableWrapper>
      <CarouselContainer>
        <SwipeableList ref={wrapperRef}>
          {photos.map((photo, index) => {
            const imageUrl = photo.get('url')
            return (
              <ImageContainer
                key={`${imageUrl}-${index}-lightbox`}
                id={'lightbox-' + index}
                ref={(element) => (entriesRef.current[index] = element)}
              >
                <TransformWrapper
                  initialScale={1}
                  disablePadding={true}
                  minScale={1}
                  maxScale={3}
                  doubleClick={{
                    disabled: true
                  }}
                  wheel={{
                    disabled: true
                  }}
                  panning={{
                    disabled: zoomedOut
                  }}
                  onPinchingStop={(_, ev) => {
                    handleOnPinchingStop({ ev, photo: imageUrl })
                  }}
                >
                  <TransformComponent>
                    <StyledMobileImage
                      alt={alt + activeImage}
                      src={imageTools.optimizeImage(imageUrl)}
                      brightness={imageBrightness}
                      loading='lazy'
                    />
                  </TransformComponent>
                </TransformWrapper>
              </ImageContainer>
            )
          })}
        </SwipeableList>
      </CarouselContainer>
      <Dots invertedColors={true} slides={photos} activeIndex={activeIndex} />
      {specialImageInfo && (
        <MobileImageDescription design='body2' center noMargin color='white'>
          {specialImageInfo}
        </MobileImageDescription>
      )}
    </SwipeableWrapper>
  )
}

const DesktopImage = ({
  photos,
  activeImage,
  alt,
  subTextOnImage,
  deactivateZoomFunction,
  imageBrightness
}) => {
  const [zoomEnabled, setZoomEnabled] = useState(false)
  const { t } = useTranslation('general')

  useEffect(() => {
    return () => setZoomEnabled(false)
  }, [activeImage])

  const handleZoomOnHover = useCallback((ev) => {
    const { left, top, width, height } = ev.target.getBoundingClientRect()
    const x = ((ev.pageX - left) / width) * 100
    const y = ((ev.pageY - top) / height) * 100
    ev.target.style.backgroundPosition = `${x}% ${y}%`
  }, [])

  const activeImageUrl = photos.get(activeImage).get('url')
  const specialImageInfo =
    subTextOnImage && photos.get(activeImage) && subTextOnImage(photos.get(activeImage))
  const zoomDisabledForSpecificImage =
    activeImageUrl.startsWith('data:') || // Don't zoom base64 or images from glue-storage, they are not yet uploaded and can't be optimized to fit zoom.
    activeImageUrl.includes('glue-storage') ||
    (deactivateZoomFunction && deactivateZoomFunction(activeImageUrl))

  // TODO: In future, try not to use optimizeImage since we probably want the original image without running optimization
  // This would remove zoomDisabledForSpecificImage.
  return (
    <StyledFigure
      zoomedImage={imageTools.optimizeImage(activeImageUrl, {
        format: 'jpeg'
      })}
      onClick={() => {
        !zoomDisabledForSpecificImage && setZoomEnabled(!zoomEnabled)
        DataLayer.trackImageZoomClick()
      }}
      onMouseMove={(ev) => zoomEnabled && handleZoomOnHover(ev)}
      zoomEnabled={zoomEnabled}
      brightness={imageBrightness}
    >
      <DesktopInnerImg>
        <StyledDesktopImage
          alt={alt + activeImage}
          src={imageTools.optimizeImage(activeImageUrl)}
          brightness={imageBrightness}
        />
        {specialImageInfo && (
          <ImageDescription design='body2' center noMargin color='white'>
            {specialImageInfo}
          </ImageDescription>
        )}
      </DesktopInnerImg>
      {!zoomEnabled && !zoomDisabledForSpecificImage && (
        <ZoomToolTip>
          <P design={'body4'} noMargin color='white'>
            {t('clickToZoom')}
          </P>
        </ZoomToolTip>
      )}
    </StyledFigure>
  )
}
