import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { Button } from '@sellpy/design-system-react-web'
import { region as regionFunctions } from '@sellpy/commons'
import { cookieSettingsModalOpen } from '../../common/ui/actions'
import { SimpleModal, P, A } from '../uiComponents'
import { getDataUsageConsentForUser, setDataUsageConsentForUser } from '../../common/user/actions'
import {
  PERMISSION_TYPE_ANALYTICS,
  PERMISSION_TYPE_EXTERNAL_MARKETING
} from '../../common/user/dataHandling'
import Toggle from '../uiComponents/toggle/Toggle.jsx'

const StyledModal = styled(SimpleModal)`
  z-index: 2000;
`

const SetCookieWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 16px 0;
`

const SetCookie = ({ checked, onChange, disabled, headline, body }) => {
  return (
    <>
      <SetCookieWrapper>
        <P design='h7' noMargin>
          {headline}
        </P>
        <Toggle toggle={checked || disabled} onChange={onChange} disabled={disabled} />
      </SetCookieWrapper>
      <P noMargin design={'body1'}>
        {body}
      </P>
    </>
  )
}

const CookieSettingsModal = () => {
  const { t } = useTranslation('general')
  const dispatch = useDispatch()
  const open = useSelector((state) => state.ui.cookieSettingsModalOpen)
  const dataUsageConsent = useSelector((state) => state.user.dataUsageConsent)

  const activeAnalyticsConsent = Boolean(dataUsageConsent.get(PERMISSION_TYPE_ANALYTICS))
  const activeExternalMarketingConsent = Boolean(
    dataUsageConsent.get(PERMISSION_TYPE_EXTERNAL_MARKETING)
  )

  const [analyticsConsent, setAnalyticsConsent] = useState()
  const [marketingConsent, setMarketingConsent] = useState()

  useEffect(() => {
    setAnalyticsConsent(activeAnalyticsConsent)
    setMarketingConsent(activeExternalMarketingConsent)
  }, [activeAnalyticsConsent, activeExternalMarketingConsent])

  const onClose = () => {
    dispatch(getDataUsageConsentForUser(null, { cacheBust: true }))
    dispatch(cookieSettingsModalOpen(false))
  }

  const reloadIfRequired = ({ analyticsConsent, marketingConsent }) => {
    if (
      (analyticsConsent != null && Boolean(analyticsConsent) === false && activeAnalyticsConsent) ||
      (marketingConsent != null &&
        Boolean(marketingConsent) === false &&
        activeExternalMarketingConsent)
    ) {
      window.location.reload()
    }
  }

  if (analyticsConsent === undefined || marketingConsent === undefined) return null
  return (
    <StyledModal isOpen={open} onClose={onClose} headline={t('cookieSettingsModal.headline')}>
      <P design='body1'>{t('cookieSettingsModal.body1')}</P>
      <P design='body1'>
        <Trans i18nKey={'general:cookieSettingsModal.body2'} design={'body5'}>
          part <A href={regionFunctions.cookiePolicyLink(process.env.REGION)}>part1</A>part2
        </Trans>
      </P>
      <SetCookie
        disabled={true}
        headline={t('cookieSettingsModal.cookieSettings.NecessaryCookies.headline')}
        body={t('cookieSettingsModal.cookieSettings.NecessaryCookies.body')}
      />
      <SetCookie
        checked={analyticsConsent}
        onChange={() => setAnalyticsConsent(!analyticsConsent)}
        headline={t('cookieSettingsModal.cookieSettings.analytics.headline')}
        body={t('cookieSettingsModal.cookieSettings.analytics.body')}
      />
      <SetCookie
        checked={marketingConsent}
        onChange={() => setMarketingConsent(!marketingConsent)}
        headline={t('cookieSettingsModal.cookieSettings.marketing.headline')}
        body={t('cookieSettingsModal.cookieSettings.marketing.body')}
      />
      <Button
        label={t('cookieSettingsModal.acceptAll')}
        color={'black'}
        fullWidth
        style={{ marginTop: '16px' }}
        onClick={() => {
          dispatch(
            setDataUsageConsentForUser({
              type: PERMISSION_TYPE_ANALYTICS,
              consent: true
            })
          )
          dispatch(
            setDataUsageConsentForUser({
              type: PERMISSION_TYPE_EXTERNAL_MARKETING,
              consent: true
            })
          )
          setAnalyticsConsent(true)
          setMarketingConsent(true)
          onClose()
        }}
      />
      <Button
        label={t('cookieSettingsModal.acceptNecessaryOnly')}
        variant='outlined'
        color={'black'}
        fullWidth
        onClick={async () => {
          await Promise.all([
            dispatch(
              setDataUsageConsentForUser({
                type: PERMISSION_TYPE_ANALYTICS,
                consent: false
              })
            ),
            dispatch(
              setDataUsageConsentForUser({
                type: PERMISSION_TYPE_EXTERNAL_MARKETING,
                consent: false
              })
            )
          ])
          reloadIfRequired({ analyticsConsent: false, marketingConsent: false })
          setAnalyticsConsent(false)
          setMarketingConsent(false)
          onClose()
        }}
        style={{ marginTop: 8 }}
      />
      <Button
        label={t('cookieSettingsModal.setCookieSettings')}
        color={'black'}
        fullWidth
        style={{ marginTop: '8px' }}
        variant='outlined'
        onClick={async () => {
          await Promise.all([
            dispatch(
              setDataUsageConsentForUser({
                type: PERMISSION_TYPE_ANALYTICS,
                consent: Boolean(analyticsConsent)
              })
            ),
            dispatch(
              setDataUsageConsentForUser({
                type: PERMISSION_TYPE_EXTERNAL_MARKETING,
                consent: Boolean(marketingConsent)
              })
            )
          ])
          reloadIfRequired({ analyticsConsent, marketingConsent })
          onClose()
        }}
      />
    </StyledModal>
  )
}

export default CookieSettingsModal
