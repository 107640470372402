import React from 'react'
import { itemCategoryMapper } from '@sellpy/commons'
import {
  BabyBottleColorful,
  BagColorful,
  Blob,
  CashColorful,
  DeliveryBoxColorful,
  EyeColorful,
  LipstickColorful,
  MagnifierCategoriesColorful,
  ManShirtColorful,
  BagHeartColorful,
  PercentageTagColorful,
  QuestionmarkColorful,
  RecycleColorful,
  SellpyCircleColorful,
  StarBlueColorful,
  StarTagColorful,
  SunColorful,
  WalletColorful,
  WomanShirtColorful
} from '@sellpy/design-system-react-web'
import { SIZES } from '../uiComponents'
import { FEATURE_BAG_PICKUP, FEATURE_P2P, FEATURE_SELL } from '../featureToggle'
import { locale } from '../../common/region/locale'
import { blobColorSelector } from '../uiComponents/constants'

const imageStyle = { height: 24, width: 24, paddingRight: 16 }

export const staticContentLoggedInCategories = {
  circle: [
    { headline: 'listed', to: '/user/circle/listed' },
    { headline: 'available-for-circle', to: '/user/circle/available-for-circle' },
    { headline: 'to-be-sent', to: '/user/circle/to-be-sent' },
    { headline: 'sent', to: '/user/circle/sent' },
    { headline: 'pending-sale', to: '/user/circle/pending-sale' },
    { headline: 'claim', to: '/user/circle/claim' }
  ],
  buyRequest: [
    { headline: 'buy-request-sent', to: '/user/circle/buy-request/buy-request-sent' },
    { headline: 'buy-request-received', to: '/user/circle/buy-request/buy-request-received' },
    { headline: 'buy-request-for-sale', to: '/user/circle/buy-request/buy-request-for-sale' }
  ]
}

const initialAlgoliaCategories = [
  {
    label: itemCategoryMapper.baseCategories(locale).women,
    image: <WomanShirtColorful style={imageStyle} />,
    value: itemCategoryMapper.baseCategories(locale).women
  },
  {
    label: itemCategoryMapper.baseCategories(locale).men,
    image: <ManShirtColorful style={imageStyle} />,
    value: itemCategoryMapper.baseCategories(locale).men
  },
  {
    label: itemCategoryMapper.baseCategories(locale).children,
    image: <BabyBottleColorful style={imageStyle} />,
    value: itemCategoryMapper.baseCategories(locale).children
  },
  {
    label: itemCategoryMapper.baseCategories(locale).things,
    image: <WalletColorful style={imageStyle} />,
    value: itemCategoryMapper.baseCategories(locale).things
  },
  {
    label: itemCategoryMapper.baseCategories(locale).beauty,
    image: <LipstickColorful style={imageStyle} />,
    value: itemCategoryMapper.baseCategories(locale).beauty
  }
]
export const initialCategoryFilterList = {
  level: 0,
  data: initialAlgoliaCategories
}

export const initialBuyOrSellList = {
  level: 0,
  buy: [
    {
      label: 'allProducts',
      image: <MagnifierCategoriesColorful style={imageStyle} />,
      link: '/search'
    },
    ...initialAlgoliaCategories,
    {
      label: 'premiumSelection',
      image: <StarBlueColorful style={imageStyle} />,
      link: '/store/selection/premium-selection'
    },
    {
      label: 'hiddenGems',
      image: <SunColorful style={imageStyle} />,
      link: '/store/selection/hidden-gems'
    },
    {
      label: 'new',
      image: <EyeColorful style={imageStyle} />,
      link: '/search?sortBy=saleStartedAt_desc'
    },
    {
      label: 'brands',
      image: <StarTagColorful style={imageStyle} />,
      link: '/brands'
    },
    {
      label: 'stores',
      image: <PercentageTagColorful style={imageStyle} />,
      link: '/stores'
    },
    {
      label: 'about',
      image: <SellpyCircleColorful style={imageStyle} />,
      link: '/about'
    }
  ],
  sell: [
    {
      label: 'sell',
      image: <BagHeartColorful style={imageStyle} />,
      link: '/sell',
      featureToggle: FEATURE_SELL
    },
    {
      label: 'orderBag',
      image: <BagColorful style={imageStyle} />,
      link: '/order-bag',
      featureToggle: FEATURE_SELL
    },
    {
      label: 'orderPickUp',
      image: <DeliveryBoxColorful style={imageStyle} />,
      link: '/orderPickUp',
      featureToggle: FEATURE_BAG_PICKUP
    },
    {
      label: 'howToSell',
      image: <QuestionmarkColorful style={imageStyle} />,
      link: '/howItWorks'
    },
    {
      label: 'cost',
      image: <CashColorful style={imageStyle} />,
      link: '/pricingInfo'
    },
    {
      label: 'sellpyCircle',
      blob: (
        <Blob
          label='Circle'
          style={{ margin: '0 1rem' }}
          color={blobColorSelector['p2p']}
          size={SIZES.SMALL}
        />
      ),
      link: '/circle',
      image: <RecycleColorful style={imageStyle} />,
      featureToggle: FEATURE_P2P
    },
    {
      label: 'about',
      image: <SellpyCircleColorful style={imageStyle} />,
      link: '/about'
    }
  ]
}
