export const isItemSold = (status) => {
  return status === 'betald' || status === 'skickad' || status === 'hämtad'
}

export const isItemAvailable = (status) => {
  return status === 'utlagd'
}

export const isItemUnavailable = (status) => {
  return status === 'ej såld' || status === 'skänkt'
}

export const itemStateMap = {
  vilande: 'pendingSale',
  registrerad: 'pendingSale',
  försäljningBegärd: 'pendingSale',
  ombedömningBegärd: 'pendingReevaluation',
  utlagd: 'ongoingSale',
  såld: 'pendingPayment',
  utsorterad: 'rejected',
  betald: 'sold',
  hämtad: 'sold',
  skickad: 'sold',
  skänkt: 'donated',
  'ej såld': 'donated',
  skänktDirekt: 'donated',
  tillbakaskickad: 'sellerReturned',
  tillbakaskickadDirekt: 'sellerReturned',
  awaitingApproval: 'awaitingApproval',
  reklamerat: 'claimed'
}

export const itemStateToStatuses = Object.keys(itemStateMap).reduce((acc, key) => {
  const value = itemStateMap[key]
  const list = acc[value] || []
  acc[value] = [...list, key]
  return acc
}, {})
