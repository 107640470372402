import { List, Map } from 'immutable'
import { marketplace, money, sizes } from '@sellpy/commons'
import isNil from 'lodash/isNil'
import { ITEM_INDEX_FACETS } from '../../../common/lib/algolia'
import { region } from '../../../common/region/region'
import { inAbTestTestGroup } from '../../../common/analytics/abTest'

export const searchStateToFilterList = ({ searchState, t }) => {
  const filterList = Object.entries(searchState).map(([key, value]) => {
    if (key === 'title') return value
    if (key === 'hierarchicalMenu') {
      return t('search:searchBar.category', { category: value?.['categories.lvl0'][0] })
    }
    if (key === 'refinementList') {
      const refinementList = Object.entries(value).map(([key, value]) => {
        if (key === 'sizes') {
          return value.map((filter) => sizes.getSimpleOutputSize(filter))
        }
        if (key === 'salesChannel') {
          return value[0] === 'market' ? t('search:market') : t('search:tradera')
        }
        if (key === 'storageSite') {
          return t('search:fastDeliveryLabel')
        }
        if (key === 'saleType') return t('search:privateSellerLabel')
        if (key === 'metadata.featuredIn') {
          return null
        }
        if (searchState.title) return null
        return value
      })
      return refinementList.flat()
    }
    if (key === 'range') {
      const [min, max] = value[`price_${region()}.amount`]?.split?.(':') || []
      if (min && !max) {
        return t('search:rangeMin', { min: toBaseUnit(min) })
      } else if (max && !min) {
        return t('search:rangeMax', { max: toBaseUnit(max) })
      } else if (min && max)
        return t('search:range', {
          min: toBaseUnit(min),
          max: toBaseUnit(max)
        })
    }
  })

  return filterList.flat().filter(Boolean)
}

export const searchStateToTitle = ({ searchState, t }) => {
  if (searchState.query) return `"${searchState.query}"`
  const categories = searchState.hierarchicalMenu?.['categories.lvl0']
  if (categories) return categories[categories.length - 1]
  return t('search:allProductsHeader')
}

export const cleanSearchState = (node) => {
  if (typeof node !== 'object') return node
  else if (node instanceof Map) {
    const newNode = node.reduce((clean, value, key) => {
      const cleanedValue = cleanSearchState(value)
      return cleanedValue || cleanedValue === 0 ? clean.set(key, cleanedValue) : clean.delete(key)
    }, node)
    return newNode.size ? newNode : null
  } else if (node instanceof List) {
    return node.size ? node : null
  }
}

const shouldOmitItem = (item) =>
  inAbTestTestGroup('quickFilters')
    ? item.attribute === 'objectID'
    : item.attribute === 'categories.lvl0' || item.attribute === 'objectID'

const toBaseUnit = (amount) =>
  money.toBaseUnit({
    amount: Number(amount),
    currency: marketplace.CURRENCY[region()]
  }).amount

export const translateKeys = ({ items, tSearch, sizeProfile }) => {
  return items.map((item) => {
    const newItem = Object.assign({}, item)
    if (newItem.label === 'tradera') {
      newItem.label = tSearch('tradera')
    } else if (newItem.label === 'sizes' && sizeProfile) {
      newItem.label = `${sizeProfile.get('name')}`
    } else if (newItem.label === 'market') {
      newItem.label = tSearch('market')
    } else if (newItem.attribute === ITEM_INDEX_FACETS.price) {
      if (newItem.min && newItem.max) {
        newItem.label = tSearch('priceRange', {
          min: toBaseUnit(newItem.min),
          max: toBaseUnit(newItem.max)
        })
      } else if (newItem.min) {
        newItem.label = tSearch('priceRangeMin', { min: toBaseUnit(newItem.min) })
      } else if (newItem.max) {
        newItem.label = tSearch('priceRangeMax', { max: toBaseUnit(newItem.max) })
      }
    } else if (newItem.attribute === ITEM_INDEX_FACETS.innerLegLength) {
      if (!isNil(newItem.min) && !isNil(newItem.max)) {
        newItem.label = tSearch('innerLegLengthRange', {
          min: newItem.min,
          max: newItem.max
        })
      } else if (!isNil(newItem.min)) {
        newItem.label = tSearch('innerLegLengthRangeMin', { min: newItem.min })
      } else if (!isNil(newItem.max)) {
        newItem.label = tSearch('innerLegLengthRangeMax', { max: newItem.max })
      }
    } else if (newItem.attribute === ITEM_INDEX_FACETS.heelHeight) {
      if (!isNil(newItem.min) && !isNil(newItem.max)) {
        newItem.label = tSearch('heelHeightRange', {
          min: newItem.min,
          max: newItem.max
        })
      } else if (!isNil(newItem.min)) {
        newItem.label = tSearch('heelHeightRangeMin', { min: newItem.min })
      } else if (!isNil(newItem.max)) {
        newItem.label = tSearch('heelHeightRangeMax', { max: newItem.max })
      }
    } else if (newItem.attribute === 'user') {
      item.label = tSearch('user')
    } else if (newItem.attribute === 'lastChance') {
      item.label = tSearch('lastChance')
    } else if (item.attribute === 'storageSite') {
      newItem.label = tSearch('fastDeliveryLabel')
    } else if (newItem.attribute === 'inHmResell_SE') {
      item.label = tSearch('hmResell')
    } else if (newItem.attribute === 'inHmResell_DE') {
      item.label = tSearch('hmResell')
    } else if (newItem.attribute === 'saleType') {
      item.label = tSearch('privateSellerLabel')
    }
    if (newItem.label.startsWith('-')) {
      newItem.label = tSearch('exclude', { filter: newItem.label.replace('-', '') })
    }
    return newItem
  })
}

export const handleSpecialKeys = ({ items, itemPackagings, tSearch }) => {
  let weightKeyExists, itemPackagingKeyExists
  itemPackagings = itemPackagings
    ? itemPackagings
        .map((packaging) => packaging.get('objectId'))
        .toList()
        .toArray()
    : []
  items = items.reduce((items, item) => {
    if (item.label.includes('weight')) {
      weightKeyExists = true
      return items
    } else if (itemPackagings.includes(item.label)) {
      itemPackagingKeyExists = true
      return items
    } else if (item.attribute && item.attribute.includes('p2p')) {
      return items
    } else if (shouldOmitItem(item)) {
      return items
    } else if (item.attribute === 'query') {
      item.label = `'${item.value}'`
      items.splice(0, 0, item)
      return items
    } else {
      items.push(item)
      return items
    }
  }, [])
  if (weightKeyExists && itemPackagingKeyExists) {
    items.push({
      label: tSearch('noExtraShippingCost'),
      value: (nextState) => {
        delete nextState.range.weight
        delete nextState.refinementList.itemPackaging
        return nextState
      }
    })
  }
  return items
}

export const categoryFromQuery = (query) => query?.hierarchicalMenu?.['categories.lvl0']
