import { localizedMetadata } from '../../../common/region/locale.js'

const formDefectDescription = ({ fallbackText, defectsDescriptions, photo }) => {
  if (!defectsDescriptions) return
  const defect = defectsDescriptions.find(({ id }) => id === photo.get('defectId'))

  if (!defect || !(defect.type || defect.location)) return fallbackText
  const { type, location } = defect

  return location ? `${type} (${location})` : type
}

const formatFabricDescription = ({ t }) => t('fabricDescription')

const formatDescriptions = ({ type, descriptionProperties }) => {
  switch (type) {
    case 'defect':
      return formDefectDescription(descriptionProperties)
    case 'fabric':
      return formatFabricDescription(descriptionProperties)
    default:
      return null
  }
}

const HM_BUCKET_URL = 'public.assets.hm'

const imageLabelFormat = ({ photo, t, defectsDescriptions, item }) => {
  const activeImageUrl = photo.get('url')
  if (activeImageUrl.includes(HM_BUCKET_URL)) {
    const brand = item.get('metadata').get('brand')
    return t('carousel.imageFromBrand', { brand })
  }
  const type = photo.get('type')

  return formatDescriptions({
    type,
    descriptionProperties: {
      defectsDescriptions,
      item,
      photo,
      fallbackText: t('defectDefault'),
      t
    }
  })
}

export const getImageLabel = ({ item, ...args }) => {
  const metadata = item.get(localizedMetadata) || item.get('metadata')
  const defectsDescriptions = metadata?.get('defects')?.toJS()

  return imageLabelFormat({ ...args, defectsDescriptions, item })
}
