import { marketplace } from '@sellpy/commons'
import config from 'config'
import Parse from 'parse'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterProvider, createBrowserRouter, defer } from 'react-router-dom'
import { captureException } from '@sentry/react'
import { initFeatureToggle } from '../market/src/client/featureToggle/index.js'
import { region as getRegion } from '../market/src/common/region/region.js'
import { initializeParse } from '../market/src/client/lib/parse.js'
import LoadingDots from '../market/src/client/components/LoadingDots.jsx'
import CheckoutItem from './CheckoutItem.jsx'
import ContainerOrder from './ContainerOrder.jsx'

const PaymentProviders = () => {
  const { i18n } = useTranslation(['adyenSessionPayment', 'cart'])
  const region = getRegion()

  useEffect(() => {
    i18n.changeLanguage(marketplace.LOCALE[region])
  }, [region, i18n])

  useEffect(() => {
    initFeatureToggle()
  }, [region])

  return <RouterProvider router={router} />
}

export default PaymentProviders

/**
 * @async
 * @param {Object} request
 * @returns {Promise<Parse.User|null>}
 */
const passwordlessHook = async ({ request }) => {
  const url = new URL(request.url)
  const authToken = url.searchParams.get('authToken')
  if (!authToken) return null

  try {
    const sessionToken = await Parse.Cloud.run('passwordless_login', { token: authToken })
    if (sessionToken) {
      const user = await Parse.User.become(sessionToken)
      return user
    }
  } catch (error) {
    captureException(error)
  }

  try {
    /**
     * Check if the user is already logged in, this is only relevant for old app versions,
     * since some old app versions aren't using incognito mode
     * in the container order flow (v1.7.57 and older).
     *
     */
    const user = await Parse.Cloud.run('currentUser')
    if (user) return user
  } catch (e) {
    captureException(e)
    if (e.code === Parse.Error.INVALID_SESSION_TOKEN) {
      await Parse.User.logOut()
    }
  }

  return null
}

const passwordLessUserLoginLoader = async ({ request }) => {
  const loginUser = async () => {
    initializeParse(config.parse)
    const user = await passwordlessHook({ request })
    return user
  }

  return defer({ userPromise: loginUser() })
}

/**
 * Some routes, for example: `'advance-payment/:paymentId'` is a route that will be navigated to by `AdyenSessionPayment`.
 * Normally the payment is processed in [AdyenAdvancePayment.jsx](../market/src/client/checkout/AdyenAdvancePayment.jsx)
 * but since the app is hijacking this redirect we don't need to process it here. But react router still needs a route
 * defined for it (otherwise it will crash).
 *
 * Another example is the route `'payment'` that is navigated to if a purchase is only made with credits. The same applies
 * here, the app hijacks the redirect and we don't need to process it here. But react router still needs a route defined.
 * ---
 * That is why we define a catch all route `'*'` that will render loading dots. This is to prevent the web from crashing
 * and hiding the default 404 page for a microsecond for the user in the app web-view.
 */
const router = createBrowserRouter([
  {
    path: '/order-bag',
    loader: passwordLessUserLoginLoader,
    element: <ContainerOrder />
  },
  {
    path: '/',
    loader: passwordLessUserLoginLoader,
    element: <CheckoutItem />
  },
  {
    path: '*',
    element: (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LoadingDots size='large' />
      </div>
    )
  }
])
