import { region as regionFunctions } from '@sellpy/commons'

/**
 * Since app payments don't have region as env. Do we need to read the
 * region from window for app payments.
 */
export const region = () => {
  if (window.paymentData) {
    const paymentData = JSON.parse(window.paymentData)
    if (paymentData?.region) return paymentData?.region
  }
  return process.env.REGION
}

// Mentioned in media (showed on about-us page)
export const mediaChannels = regionFunctions.mediaChannels(region())

// Cancellation policy link (showed in footer)
export const showCancellationPolicy = regionFunctions.showCancellationPolicy(region())

// External review service on payment and order page
export const externalReviewService = regionFunctions.externalReviewService(region())

export const showTrustScore = regionFunctions.showTrustScore(region())

export const trustPilotLink = regionFunctions.trustPilotLink(region())

export const trustPilotTemplateId = regionFunctions.trustPilotTemplateId(region())

export const trustPilotBusinessUnitId = regionFunctions.trustPilotBusinessUnitId(region())

export const adyenPaymentOptions = regionFunctions.adyenPaymentOptions(region())

export const paypalMerchantId = regionFunctions.paypalMerchantId(region())

export const paymentServiceProviders = regionFunctions.paymentServiceProviders(region())

// Check if we should show bank account fields (clearing no. and account no.)
export const internationalBankAccountFields = regionFunctions.internationalBankAccountFields(
  region()
)

// Check if we should have environment bag pre-checked in order-bag form
export const preCheckIncludeEnvironmentBag = regionFunctions.preCheckIncludeEnvironmentBag(region())

// Should we show sales info link on itemPricing
export const showSalesInfoLink = regionFunctions.showSalesInfoLink(region())

// Which postnordMyPackLogo logo to choose, or if we should use postnord partner-logo (for NL, e.g.)
export const postnordMyPackLogo = regionFunctions.postnordMyPackLogo(region())

export const languageLocalisationCode = regionFunctions.languageLocalisationCode(region())

export const defaultAddressOptionalFields = () =>
  regionFunctions.defaultAddressOptionalFields(region())

export const validShippingCountries = regionFunctions.validShippingCountries(region())

export const vatMultiplier = regionFunctions.vatMultiplier(region())

export const supportPhone = regionFunctions.supportPhone(region())

export const company = regionFunctions.company(region())

export const contactAddress = regionFunctions.contactAddress(region())

export const customerClaimSelfAddress = regionFunctions.customerClaimSelfAddress(region())

export const firstTimeMarketPurchaseFreeFreightPromocode = regionFunctions.firstTimeMarketPurchaseFreeFreightPromocode(
  region()
)

export const pricingInfoExamples = regionFunctions.pricingInfoExamples(region())

export const nrOfAllowedBagsPerOrder = regionFunctions.nrOfAllowedBagsPerOrder(region())

export const charityOrganizations = regionFunctions.getOrganizationsForRegion(region())

export const dropOffServicePointsWebsite = regionFunctions.dropOffServicePointsWebsite(region())

export const isLoyaltyEnabled = regionFunctions.isLoyaltyEnabled(region())
