import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from '@sellpy/design-system-react-web'
import styled from 'styled-components'
import { mediaQueries } from '../../../../../../uiComponents/mediaQueries.js'
import { getLatestSearches } from '../../../../../../../common/localStorage.js'
import useResponsive from '../../../../../../hooks/useResponsive.js'
import { SuggestionListContainer, SuggestionListHeader } from '../components/SuggestionList.jsx'
import { SuggestionItem } from '../components/SuggestionItem.jsx'

export const ChipList = styled.ul`
  list-style-type: 'none';
  padding: 0 16px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  ${mediaQueries.tablet} {
    padding-bottom: 4px;
  }
`

export const ChipSlider = styled.ul`
  list-style-type: 'none';
  overflow: auto;
  display: flex;
  gap: 8px;
  padding: 0 16px;
  margin: 12px 0 0;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const QuerySuggestions = ({ items, source, getListProps, getItemProps, hasQuery }) => {
  const { t } = useTranslation('search')
  const { isTablet } = useResponsive()

  const hasLatestSearches = getLatestSearches().length > 0

  const isSlider = isTablet && (hasQuery || hasLatestSearches)

  const itemsToRender = items.map((item) => {
    return (
      <SuggestionItem key={item.objectID} getItemProps={getItemProps} item={item} source={source}>
        <Chip title={item.query.charAt(0).toUpperCase() + item.query.slice(1)} color='lightBlue' />
      </SuggestionItem>
    )
  })

  return (
    <SuggestionListContainer>
      {!isSlider && (
        <SuggestionListHeader $isChipList={!isSlider}>
          {t(`suggestions.querySuggestions.${hasQuery ? 'headerFilled' : 'header'}`)}
        </SuggestionListHeader>
      )}
      {isSlider ? (
        <ChipSlider {...getListProps()}>{itemsToRender}</ChipSlider>
      ) : (
        <ChipList {...getListProps()}>{itemsToRender}</ChipList>
      )}
    </SuggestionListContainer>
  )
}
