import { useSelector } from 'react-redux'
import { ICONS, Icon, Button, P, SingleLineInput, Chip } from '@sellpy/design-system-react-web'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { gql, useFragment, useMutation } from '@apollo/client'
import { createPortal } from 'react-dom'
import { SimpleModal, SIZES } from '../../uiComponents'
import { currentUserSelector } from '../../../common/user/selectors'
import { mediaQueries } from '../../uiComponents/mediaQueries'
import { useResponsive } from '../../hooks'
import SingleSelector from '../../uiComponents/input/selectors/singleSelector/SingleSelector.jsx'
import FormWrapper from '../../components/ui/form/FormWrapper.jsx'
import { inAbTestTestGroup } from '../../../common/analytics/abTest.js'
import { GET_FAVORITE_LISTS } from './filters/FavoritesListSelector.jsx'

export const StyledButton = styled(Button)`
  ${mediaQueries.tablet} {
    width: 100%;
  }
`

const _FavoriteModal = ({ showPopup, setShowPopup, editOrCreate, favoriteList, t }) => {
  return createPortal(
    <SimpleModal
      isOpen={Boolean(showPopup)}
      headline={
        editOrCreate === 'edit' ? t('createFavorite.updateHeadLine') : t('createFavorite.headLine')
      }
      onClose={() => setShowPopup('')}
    >
      <CreateOrUpdateFavoriteListForm
        editOrCreate={editOrCreate}
        favoriteList={favoriteList}
        afterSubmit={() => setShowPopup(false)}
        onClose={() => setShowPopup(false)}
      />
    </SimpleModal>,
    document.querySelector('#portal-root')
  )
}

const favoriteListFragment = gql`
  fragment favoriteList on ItemFavoriteList {
    objectId
    name
    public
    user {
      objectId
    }
  }
`

const CreateFavoritesList = () => {
  const params = useParams()
  const selectedList = params.id
  const { isMobile } = useResponsive()
  const { t } = useTranslation('favorite')
  const [showPopup, setShowPopup] = useState(false)
  const [editOrCreate, setEditOrCreate] = useState('')
  const currentUser = useSelector(currentUserSelector)
  const { data: favoriteList } = useFragment({
    fragment: favoriteListFragment,
    from: `ItemFavoriteList:${selectedList}`
  })
  const listOwner = favoriteList?.user?.objectId
  const isInQuickFiltersTest = inAbTestTestGroup('quickFilters')

  return (
    <div>
      {listOwner === currentUser ? (
        isInQuickFiltersTest ? (
          <Chip
            title={t('createFavorite.edit')}
            variant='text'
            onClick={() => {
              setEditOrCreate('edit')
              setShowPopup(true)
            }}
            leftIcon={ICONS.SETTINGS}
          />
        ) : (
          <StyledButton
            size={SIZES.SMALL}
            color={isMobile ? 'white' : 'grey'}
            label={t('createFavorite.edit')}
            onClick={() => {
              setEditOrCreate('edit')
              setShowPopup(true)
            }}
            rightIcon={!isMobile ? <Icon name={ICONS.SETTINGS} /> : null}
            leftIcon={isMobile ? <Icon name={ICONS.SETTINGS} /> : null}
          />
        )
      ) : !selectedList ? (
        isInQuickFiltersTest ? (
          <Chip
            title={t('createFavorite.createNewList')}
            variant='text'
            onClick={() => {
              setEditOrCreate('create')
              setShowPopup(true)
            }}
            leftIcon={ICONS.SETTINGS}
          />
        ) : (
          <StyledButton
            size={SIZES.SMALL}
            color={isMobile ? 'white' : 'grey'}
            label={t('createFavorite.createNewList')}
            onClick={() => {
              setEditOrCreate('create')
              setShowPopup(true)
            }}
            rightIcon={!isMobile ? <Icon name={ICONS.ADD} /> : null}
            leftIcon={isMobile ? <Icon name={ICONS.ADD} /> : null}
          />
        )
      ) : null}
      <_FavoriteModal
        showPopup={showPopup}
        editOrCreate={editOrCreate}
        setShowPopup={setShowPopup}
        favoriteList={favoriteList}
        t={t}
      />
    </div>
  )
}

export default CreateFavoritesList

const CREATE_FAVORITE_LIST = gql`
  mutation createItemFavoriteList($name: String!, $publicList: Boolean) {
    addFavoriteList(name: $name, publicList: $publicList) {
      objectId
      name
      public
    }
  }
`

const EDIT_FAVORITE_LIST = gql`
  mutation editFavoriteList($favoriteListId: ID!, $name: String, $publicList: Boolean) {
    editFavoriteList(favoriteListId: $favoriteListId, name: $name, publicList: $publicList) {
      objectId
      name
      public
    }
  }
`

const DELETE_FAVORITE_LIST = gql`
  mutation deleteFavoriteList($favoriteListId: ID!) {
    deleteList(favoriteListId: $favoriteListId) {
      objectId
    }
  }
`

export const CreateOrUpdateFavoriteListForm = ({
  editOrCreate,
  favoriteList,
  afterSubmit,
  onClose
}) => {
  const { t } = useTranslation('favorite')
  const navigate = useNavigate()
  const currentUser = useSelector(currentUserSelector)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control
  } = useForm({
    defaultValues: {
      name: editOrCreate === 'edit' ? favoriteList && favoriteList.name : '',
      publicList: favoriteList ? (favoriteList.public ? 'public' : 'hidden') : 'public'
    }
  })
  const [createFavoriteList] = useMutation(CREATE_FAVORITE_LIST, {
    refetchQueries: [
      {
        query: GET_FAVORITE_LISTS,
        variables: { userId: currentUser }
      }
    ]
  })
  const [editFavoriteList] = useMutation(EDIT_FAVORITE_LIST, {
    refetchQueries: [
      {
        query: GET_FAVORITE_LISTS,
        variables: { userId: currentUser }
      }
    ]
  })
  const [deleteFavoriteList] = useMutation(DELETE_FAVORITE_LIST, {
    refetchQueries: [
      {
        query: GET_FAVORITE_LISTS,
        variables: { userId: currentUser }
      }
    ]
  })

  const onSubmit = ({ name, publicList }) => {
    const newName = favoriteList ? (favoriteList.name === name ? '' : name) : name
    return editOrCreate === 'edit'
      ? editFavoriteList({
          variables: {
            favoriteListId: favoriteList.objectId,
            name: newName,
            publicList: publicList === 'public'
          }
        })
          .then(() => afterSubmit())
          .catch((error) => {
            throw error
          })
      : createFavoriteList({ variables: { name, publicList: publicList === 'public' } })
          .then(() => afterSubmit())
          .catch((error) => {
            throw error
          })
  }

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <P design='body1'>{t('createFavorite.subHeadLine')}</P>
      <SingleLineInput
        {...register('name', { required: t('genericErrors.emptyField') })}
        label={t('createFavorite.name')}
        errors={errors}
        name='name'
      />
      <SingleSelector
        name={'publicList'}
        label={t('createFavorite.public')}
        options={[
          {
            label: t('createFavorite.publicAltPublic'),
            value: 'public'
          },
          {
            label: t('createFavorite.publicAltHidden'),
            value: 'hidden'
          }
        ]}
        control={control}
      />
      {editOrCreate === 'edit' && favoriteList?.objectId && (
        <Button
          onClick={() => {
            deleteFavoriteList({ variables: { favoriteListId: favoriteList.objectId } })
            onClose()
            navigate(`/my-shopping/favorites`)
          }}
          label={t('createFavorite.deleteList')}
          size={SIZES.SMALL}
          color='red'
          type='button'
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '8px'
        }}
      >
        <Button onClick={onClose} variant='outlined' label={t('createFavorite.close')} />
        <Button type='submit' loading={isSubmitting} label={t('createFavorite.save')} />
      </div>
    </FormWrapper>
  )
}
