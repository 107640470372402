import { money, marketplace } from '@sellpy/commons'
import { TOAST_TYPE } from '../uiComponents/notifications/Toasts.jsx'

/**
 * @description  Returns a zero money object, currency defaults to envionment currency
 * @param {string} currency
 * @returns {{amount: 0, currency: string}}
 */
export const zeroMoney = (currency = marketplace.CURRENCY[process.env.REGION]) => ({
  amount: 0,
  currency
})

export const sumTransactions = (transactions) =>
  money.add(
    ...transactions
      .map((transaction) => transaction.get('delta'))
      .map(money.toBaseUnit)
      .toList()
      .toArray()
  )

export const formatOutputValue = (object, creditMultiplier) =>
  money.currencyJSRoundForCreditConversion(
    money.baseUnitToCurrencyJS(money.multiply(creditMultiplier)(object))
  )

export const formatInputValue = (object, creditMultiplier) =>
  money.currencyJSRoundForCreditConversion(
    money.baseUnitToCurrencyJS(money.multiply(1 / creditMultiplier)(object))
  )

export const commonPayoutErrors = (error, t) => {
  const errorType = error?.message?.details?._error
  const body = [
    'zeroPayableAmount',
    'invalidAmounts',
    'invalidAmount',
    'accountOutsideOfEuOrEes',
    'rejectedAccount',
    'invoiceUser',
    'missingAddress'
  ].includes(errorType)
    ? t(`balance:errorToast.body.${errorType}`)
    : t('common:errorToast.body')
  return {
    header: t('common:errorToast.header'),
    body,
    type: TOAST_TYPE.ERROR
  }
}

export const normalizeAmount = ({ amount }) => amount && amount.replace(',', '.')
