import Parse from 'parse'
import { money } from '@sellpy/commons'
import { getOrder } from '../order/actions'
import { getItem } from '../items/actions'
import { analyticsClaimCompleted } from '../analytics/actions'
import { resultToMap } from '../lib/parseToImmutable'
import { cacheEntities } from '../entities/actions'
import { showToast } from '../ui/actions'
import { fetchCreditTransactionPaymentForOrder } from '../credit/actions'
import { TOAST_TYPE } from '../../client/uiComponents/notifications/Toasts.jsx'
import { runCloudCached } from '../lib/parseTools'
import { router } from '../../client/routing/Routes.jsx'

export const CREATE_CLAIM_SUCCESS = 'CREATE_CLAIM_SUCCESS'

export const createClaim = ({ itemId, orderId, paymentId, reason, motivation, images = [], t }) => {
  return (dispatch) => {
    return Parse.Cloud.run('createClaim', {
      itemId,
      orderId,
      reason,
      motivation,
      images
    })
      .then((claim) => {
        dispatch({ type: CREATE_CLAIM_SUCCESS, claim: resultToMap(claim) })
        dispatch(cacheEntities(claim))
        analyticsClaimCompleted({
          itemId: claim.get('item').id,
          paymentId
        })
        router.navigate(`/claims/${orderId}`)
      })
      .catch((error) => {
        dispatch(
          showToast({
            header: t('common:errorToast.header'),
            body: t('common:errorToast.body'),
            type: TOAST_TYPE.ERROR
          })
        )
        console.error(error)
        throw error
      })
  }
}

export const EDIT_CLAIM_SUCCESS = 'EDIT_CLAIM_SUCCESS'

export function editClaim(claimId, orderId, reason, motivation, images = [], t) {
  return (dispatch) => {
    return Parse.Cloud.run('editClaim', {
      claimId,
      reason,
      motivation,
      images
    })
      .then((claim) => {
        dispatch({ type: EDIT_CLAIM_SUCCESS, claim: resultToMap(claim) })
        dispatch(cacheEntities(claim))
        router.navigate(`/claims/${orderId}`)
      })
      .catch((error) => {
        dispatch(
          showToast({
            header: t('common:errorToast.header'),
            body: t('common:errorToast.body'),
            type: TOAST_TYPE.ERROR
          })
        )
        console.error(error)
        throw error
      })
  }
}

export const removeClaim = (claimId) => {
  return (dispatch) => {
    return Parse.Cloud.run('removeClaim', { claimId }).then((itemClaim) => {
      dispatch(cacheEntities(itemClaim))
    })
  }
}

export const fetchClaims = (orderId) => (dispatch) =>
  runCloudCached((claims) => dispatch(cacheEntities(claims)))('fetchClaimsByOrder', {
    orderId
  })

export const disputeClaim = ({ claimId, sellerMotivation }) => {
  return (dispatch) => {
    return Parse.Cloud.run('disputeClaim', { claimId, sellerMotivation }).then((claim) => {
      dispatch(cacheEntities(claim))
    })
  }
}

export const approveAndRefundP2PClaim = (claimId) => {
  return (dispatch) => {
    return Parse.Cloud.run('approveAndRefundP2PClaim', { claimId }).then(({ claim }) => {
      dispatch(cacheEntities(claim))
    })
  }
}

export const fetchClaimByItem = (itemId) => async (dispatch) =>
  runCloudCached((itemId) => dispatch(cacheEntities(itemId)))('fetchClaimByItem', { itemId })

export const INITIALIZE_NEW_CLAIM_PAGE_LOADING = 'INITIALIZE_NEW_CLAIM_PAGE_LOADING'
export const INITIALIZE_NEW_CLAIM_PAGE_SUCCESS = 'INITIALIZE_NEW_CLAIM_PAGE_SUCCESS'
export const INITIALIZE_NEW_CLAIM_PAGE_FAILURE = 'INITIALIZE_NEW_CLAIM_PAGE_FAILURE'

export const initializeNewClaimPage = (itemId, orderId) => {
  return (dispatch) => {
    dispatch({ type: INITIALIZE_NEW_CLAIM_PAGE_LOADING })
    return Promise.all([
      dispatch(getOrder(orderId)),
      dispatch(getItem(itemId)),
      dispatch(fetchCreditTransactionPaymentForOrder(orderId))
    ])
      .then(() => {
        dispatch({ type: INITIALIZE_NEW_CLAIM_PAGE_SUCCESS })
      })
      .catch((_) => {
        // If fetchCreditTransactionPaymentForOrder fails we still want to set loading to false
        dispatch({ type: INITIALIZE_NEW_CLAIM_PAGE_FAILURE })
      })
  }
}

export const GET_CLAIM_SHIPMENTS_SUCCESS = 'GET_CLAIM_SHIPMENTS_SUCCESS'

export const getClaimShipments = (orderId) => (dispatch) => {
  return Parse.Cloud.run('getClaimShipmentsForOrder', { orderId })
    .then((claimShipments) => {
      dispatch(cacheEntities(claimShipments))
      dispatch({ type: GET_CLAIM_SHIPMENTS_SUCCESS })
    })
    .catch(console.error)
}

export const getParcelStatusAlerts = (orderId) => (dispatch) => {
  return Parse.Cloud.run('getParcelStatusAlertsForOrder', { orderId }).then(
    (parcelStatusAlerts) => {
      dispatch(cacheEntities(parcelStatusAlerts))
    }
  )
}

export const requestReturnShippingLabel = (orderId, t) => (dispatch) => {
  return Parse.Cloud.run('requestReturnShippingLabel', { orderId })
    .then((claimShipment) => {
      dispatch(cacheEntities(claimShipment))
    })
    .catch((error) => {
      dispatch(
        showToast({
          header: t('common:errorToast.header'),
          body: t('common:errorToast.body'),
          type: TOAST_TYPE.ERROR
        })
      )
      console.error(error)
    })
}

export const createHmResellClaim = ({ itemId, reason, motivation, token, t }) => async (dispatch) =>
  Parse.Cloud.run('createClaimForHmResell', {
    itemId,
    reason,
    motivation,
    returnToken: token
  })
    .then((claim) => {
      dispatch(cacheEntities(claim))
    })
    .catch((error) => {
      dispatch(
        showToast({
          header: t('common:errorToast.header'),
          body: t('common:errorToast.body'),
          type: TOAST_TYPE.ERROR
        })
      )
      console.error(error)
      throw error
    })

export const requestReturnShippingLabelForHmResell = ({ token, t }) => (dispatch) => {
  return Parse.Cloud.run('requestReturnShippingLabelForHmResell', {
    returnToken: token
  })
    .then((claimShipment) => {
      dispatch(cacheEntities(claimShipment))
    })
    .catch((error) => {
      dispatch(
        showToast({
          header: t('common:errorToast.header'),
          body: t('common:errorToast.body'),
          type: TOAST_TYPE.ERROR
        })
      )
      console.error(error)
      throw error
    })
}

export const getHmResellReturnData = (returnToken) => (dispatch) =>
  Parse.Cloud.run('getHmResellReturnData', { returnToken })
    .then(({ order, itemPayments, claims, claimShipments, linkExpired }) => {
      if (linkExpired) return { linkExpired }
      dispatch(cacheEntities(claimShipments))
      dispatch(cacheEntities(claims))
      // See comments in parsoku (cloud function getHmResellReturnData) for more details
      order.set('itemPayments', itemPayments) // for the list of items
      order.set('orderPayment', money.add(...itemPayments.map(({ payment }) => payment))) // for the OrderSummary
      dispatch(cacheEntities(order))
      return { orderId: order.id }
    })
    .catch((error) => {
      console.error(error)
      throw error
    })

export const acceptPartialRefund = (claimId) => {
  return (dispatch) => {
    return Parse.Cloud.run('acceptPartialRefund', { claimId }).then((itemClaim) => {
      dispatch(cacheEntities(itemClaim))
    })
  }
}

export const denyPartialRefund = (claimId) => {
  return (dispatch) => {
    return Parse.Cloud.run('denyPartialRefund', { claimId }).then(({ claim, shipment }) => {
      dispatch(cacheEntities(claim))
      dispatch(cacheEntities(shipment))
    })
  }
}
