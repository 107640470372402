import React from 'react'
import PropTypes from 'prop-types'
import { StyledToggle, ToggleInput, Container, ToggleCircle } from './styles'

const Toggle = ({ onChange, disabled, toggle = false, v2 = false }) => {
  const triggerToggle = () => {
    if (disabled) return
    onChange(!toggle)
  }

  return (
    <StyledToggle onClick={triggerToggle} checked={toggle} disabled={disabled} v2={v2}>
      <Container checked={toggle} v2={v2} />
      <ToggleCircle checked={toggle} v2={v2} />
      <ToggleInput type='checkbox' aria-label='Toggle Button' />
    </StyledToggle>
  )
}

Toggle.propTypes = {
  disabled: PropTypes.bool,
  toggle: PropTypes.bool,
  onChange: PropTypes.func
}

export default Toggle
